import { get, post, postFormData } from 'helpers/api_helper'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, Col, Form, Input, Label, Modal, Row } from 'reactstrap'
import Select from "react-select";
import "../assets/scss/theme.scss"
import { showToast } from 'helpers/util';
import { useHistory } from 'react-router-dom';
import api from 'helpers/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

export default function NewBooking() {
  const history = useHistory();
  const [modal, setModal] = useState(true)
  const [errors, setErrors] = useState([])

  const { getValues, setValue, watch} = useForm();

  const { data: type } = useQuery(['select-patient-type'], () => api.getPatientType({
    params: { limit: 0 }
  }), {
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const { data: problem } = useQuery(['select-patient-problem'], () => api.getPatientProblem({
    params: { limit: 0 }
  }), {
    refetchOnWindowFocus: false
  });

  const submit = async () => {
    var formData = new FormData();
    var data = getValues()
    for (const key in data) {
      formData.set(key, data[key]);
    }
    if (data.referral) {
      for (let i = 0; i < data.referral.length; i++) {
        formData.set(`referral[${i}]`, data.referral[i]);
      }
    }
    var res = await postFormData(`patient-booking`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    showToast(res.message, !res.status && 'error')
    if (!res.status) {
      setErrors(res.errors)
      return;
    }else{
      history.push('/login')
    }
  }

  const { mutate, isLoading: submitLoading } = useMutation(submit);

  return (
    <>
      <Card outline color="primary">
        <CardBody className="mx-auto" style={{ display: 'contents', width: '-webkit-fill-available' }}>
          <div className="mx-auto">
            <img style={{ width: 200, height: 70 }} className="mb-5" src="https://www.myadhdcentre.com.au/wp-content/uploads/2021/08/Logo-1.png" alt="logo-dark"></img>
            <h3>New Patient Booking</h3>
          </div>

          <div className="row">
            <div className="col-md-7 mx-auto p-4 border-primary" style={{ border: '1px solid black', borderRadius: 4, boxShadow: '0 0.75rem 1.5rem rgb(18 38 63 / 3%)' }}>
              <Form onSubmit={(e) => {
                e.preventDefault()
                mutate()
              }}>
                <Card outline color="black">
                  <CardBody className="mx-auto" style={{ display: 'grid', width: '-webkit-fill-available' }}>
                    <div className="row">
                      <div className="col mb-n2">
                        <Input type="radio" name="type" value="0" id="new" onChange={() => setValue('typePatient', 'new')} />
                        <label htmlFor="formrow-new-Input" className="form-label" style={{ marginLeft: 16 }}>NEW PATIENT</label>
                      </div>
                      <div className="col mb-n2 text-end">
                        <Input type="radio" name="type" value="1" id="exist" onChange={() => setValue('typePatient', 'exist')} />
                        <label htmlFor="formrow-exist-Input" className="form-label" style={{ marginLeft: 16 }}>EXISTING PATIENT</label>
                      </div>
                    </div>
                    {errors.typePatient && <span className="form-text text-danger">{errors.typePatient[0]}</span>}
                  </CardBody>
                </Card>
                <h4 className="text-center">PERSONAL DETAILS</h4>
                <hr />
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-first-name-Input" className="text-uppercase">First Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-first-name-Input"
                        placeholder="First Name"
                        onInput={(e) => setValue('name', e.target.value)}
                      />
                      {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-last-name-Input" className="text-uppercase">Last Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-last-name-Input"
                        placeholder="Last Name"
                        onInput={(e) => setValue('last_name', e.target.value)}
                      />
                      {errors.last_name && <span className="form-text text-danger">{errors.last_name[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input" className="text-uppercase">Email</Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="formrow-email-Input"
                        placeholder="Email"
                        onInput={(e) => setValue('email', e.target.value)}
                      />
                      {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-phone-Input" className="text-uppercase">Mobile Number</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-phone-Input"
                        placeholder="Mobile Number"
                        onInput={(e) => setValue('phone', e.target.value)}
                      />
                      {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-dob-Input" className="text-uppercase">Date of Birth</Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-dob-Input"
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                        max={new Date().getFullYear() + '-12-31'}
                        placeholder="Date of Birth"
                        onInput={(e) => setValue('b_date', e.target.value)}
                      />
                      {errors.b_date && <span className="form-text text-danger">{errors.b_date[0]}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-Gender" className="text-uppercase">Gender Type</Label>
                      <select
                        id="formrow-Gender"
                        className="form-control"
                        onChange={(e) => setValue('gender', e.target.value)}
                      >
                        <option value="">Choose...</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      {errors.gender && <span className="form-text text-danger">{errors.gender[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <div className="mb-3">
                  <Label htmlFor="formrow-Address" className="text-uppercase">Address</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    id="formrow-Address"
                    onInput={(e) => setValue('address', e.target.value)}
                  />
                  {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
                </div>

                <h4 className="text-center">EMERGENCY CONTACT</h4>
                <hr />
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-first-name-Input">First Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-first-name-Input"
                        placeholder="First Name"
                        onInput={(e) => setValue('emergency_name', e.target.value)}
                      />
                      {errors?.emergency_name && <span className="form-text text-danger">{errors?.emergency_name[0]}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-last-name-Input">Last Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-last-name-Input"
                        placeholder="Last Name"
                        onInput={(e) => setValue('emergency_last_name', e.target.value)}
                      />
                      {errors?.emergency_last_name && <span className="form-text text-danger">{errors?.emergency_last_name[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-emergency-phone-Input">Phone</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-emergency-phone-Input"
                        placeholder="Phone"
                        onInput={(e) => setValue('emergency_phone', e.target.value)}
                      />
                      {errors?.emergency_phone && <span className="form-text text-danger">{errors?.emergency_phone[0]}</span>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-emergency-email-Input">Email</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-emergency-email-Input"
                        placeholder="Email"
                        onInput={(e) => setValue('emergency_email', e.target.value)}
                      />
                      {errors?.emergency_email && <span className="form-text text-danger">{errors?.emergency_email[0]}</span>}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-Relationship-Input">Relationship</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-Relationship-Input"
                        placeholder="Relationship"
                        onInput={(e) => setValue('emergency_relationship', e.target.value)}
                      />
                      {errors?.emergency_relationship && <span className="form-text text-danger">{errors?.emergency_relationship[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <h4 className="text-center">REQUIREMENTS</h4>
                <hr />

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-dob-Input" className="text-uppercase">Which Patient Type Are You?</Label>
                      <select
                        id="formrow-PatientType"
                        className="form-control"
                        onChange={(e) => setValue('patient_type', e.target.value)}
                      >
                        <option>Choose...</option>
                        {type?.map(item => (
                          <option value={item?.value} key={item?.value}>{item?.label}</option>
                        ))}
                      </select>
                      {errors.patient_type && <span className="form-text text-danger">{errors.patient_type[0]}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-PatientTypeProblem" className="text-uppercase">Main Presenting Problem</Label>
                      <select
                        id="formrow-PatientTypeProblem"
                        className="form-control"
                        onChange={(e) => setValue('patient_type_problem', e.target.value)}
                      >
                        <option>Choose...</option>
                        {problem?.map(item => (
                          <option value={item?.value} key={item?.value}>{item?.label}</option>
                        ))}
                      </select>
                      {errors.patient_type_problem && <span className="form-text text-danger">{errors.patient_type_problem[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <Row>
                  {/* <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-irn-number-Input" className="text-uppercase">IRN Number</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-irn-number-Input"
                        placeholder="IRN Number"
                        onInput={(e) => setValue('irn_number', e.target.value)}
                      />
                      {errors.irn_number && <span className="form-text text-danger">{errors.irn_number[0]}</span>}
                    </div>
                  </Col> */}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-medicare-number-Input" className="text-uppercase">Medicare Number</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-Medicare Number-Input"
                        placeholder="Medicare Number"
                        onInput={(e) => setValue('medicare_number', e.target.value)}
                      />
                      {errors.medicare_number && <span className="form-text text-danger">{errors.medicare_number[0]}</span>}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-medicare-exp-Input" className="text-uppercase">Medicare Expiry Date</Label>
                      <Input
                        type="month"
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                        max={new Date().getFullYear() + '-12-31'}
                        className="form-control"
                        id="formrow-medicare-exp-Input"
                        placeholder="Medicare Expired"
                        onInput={(e) => setValue('medicare_expired', e.target.value)}
                      />
                      {errors.medicare_expired && <span className="form-text text-danger">{errors.medicare_expired[0]}</span>}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Label htmlFor="formFile" className="form-label text-uppercase">Referral Document <i className="bx bx-question-mark rounded-circle border border-dark" data-tip="Only upload the referral document from your GP (General Practice)."></i></Label>
                      <Input className="form-control" type="file" id="formFile" multiple onChange={(e) => setValue('referral', e.target.files)} />
                    {errors.referral && <span className="form-text text-danger">{errors.referral[0]}</span>}
                    {Array.from({ length: 5 })?.map((item, i) => errors[`referral.${i}`] && <span className="form-text text-danger" key={i}>{errors[`referral.${i}`][0]}</span>)}
                    <small className="text-danger">Maximum 5 file & 20 Mb</small>
                  </Col>
                  {/* <Col md={4}>
                    <Label htmlFor="formFile" className="form-label text-uppercase">Referral Expiry Date</Label>
                    <Input
                      type="date"
                      pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                      max={new Date().getFullYear() + '-12-31'}
                      className="form-control"
                      id="formrow-referral-exp-Input"
                      placeholder="Referral Expired"
                      onInput={(e) => setValue('referral_expired', e.target.value)}
                    />
                    {errors.referral_expired && <span className="form-text text-danger">{errors.referral_expired[0]}</span>}
                  </Col> */}
                </Row>

                <div className="d-md-flex justify-content-md-end mt-3">
                  <button type="submit" className="btn btn-primary w-md" disabled={submitLoading}>
                    {submitLoading ? (
                      <>
                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                        Loading
                      </>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </CardBody>
      </Card>
      <ReactTooltip />
    </>
  )
}
