import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row, Table } from 'reactstrap';
import { get, post, put } from 'helpers/api_helper';
import { debounce } from 'lodash';
import { showToast, formatDate } from 'helpers/util';

const CreateUpdateInvoice = ({ data, toggle, modal, handleCreateModal, refresh, defaultPatient = null }) => {
  const [loading, setLoading] = useState(false)
  const [patientData, setPatientData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [productData, setProductData] = useState([])
  const [patient, setPatient] = useState('')
  const [appointment, setAppointment] = useState('')
  const [product, setProduct] = useState([{id: '', name: '', price: '', tax_type: '', sku: ''}])
  const [notes, setNotes] = useState('')
  const [errors, setErrors] = useState([])

  const addProduct = () => {
    // const p = product
    let newState = {
      id: null,
      name: '',
      price: null,
      sku: '',
      tax_type: '',
    };

    // p.push(newState)
    setProduct(prev => [...prev, newState])
  }

  const onSelectProduct = (val, i) => {
    let state = product.map(item => item);
    state[i].id = productData.find(item => val == item.value).value
    state[i].sku = productData.find(item => val == item.value).sku
    state[i].name = productData.find(item => val == item.value).name
    state[i].price = productData.find(item => val == item.value).price
    state[i].tax_type = productData.find(item => val == item.value).tax_type
    setProduct(state)
    document.getElementById("notes").click();
  }

  useEffect(() => {
    if (!data) {
      setAppointment('')
      setPatient('')
      setNotes('')
      setProduct([{ id: '', name: '', price: '', tax_type: '', sku: '' }])
    }else{
      handleAppointment()
      setAppointment(data.appointment_id === 0 ? '0' : data.appointment_id)
      setPatient(data.user_id)
      setNotes(data.notes)
      let products = [];
      data.invoice_detail?.map(item => products.push({
        id: item?.product?.id,
        sku: item?.product?.sku,
        name: item?.product?.name,
        price: item?.product?.price,
        tax_type: item?.product?.tax_type,
      }))
      setProduct(products)
    }
    if (!productData.length) {
      getProduct();
    }
  }, [data])

  useEffect(() => {
    if (defaultPatient) {
      setPatient(defaultPatient.value)
    }
  }, [defaultPatient])

  useEffect(() => {
    if (!toggle) {
      setAppointment('')
      setPatient('')
      setNotes('')
      setProduct([{ id: '', name: '', price: '', tax_type: '', sku: '' }])
    }
  }, [toggle])

  const handlePatient = useCallback(debounce((val) => {
    if (val) {
      setLoading(true)
      get('/select-patient', {
        params : {
          search: val
        }
      }).then(res => {
        let patients = [];
        res?.map(item => patients.push({ label: item?.label, value: item?.value }));
        setPatientData(patients)
        setLoading(false)
      })
    }
  }, 300), [])

  const handleAppointment = useCallback(debounce((val = null) => {
      setLoading(true)
      get('/select-appointment', {
        params: {
          search: val,
          patient
        }
      }).then(res => {
        let appointments = [];
        res?.map(item => appointments.push({ label: item?.user?.fullname + ' | ' + item?.doctor?.fullname + ' | ' + formatDate(item?.date), value: item?.id, appointment: item }));
        setAppointmentData(appointments)
        setLoading(false)
      })
  }, 300), [patient])

  const getProduct = useCallback((val) => {
    setLoading(true)
    get('/select-product', {
      params: {
        search: val
      }
    }).then(res => {
      let products = [];
      res?.map(item => products.push({
        label: item?.sku,
        value: item?.id,
        sku: item?.sku,
        name: item?.name,
        price: item?.price,
        tax_type: item?.tax_type
      }));
      setProductData(products)
      setLoading(false)
    })
  }, [])

  const handleSubmit = async() => {
    setLoading(true);
    if (data) {
      var res = await put(`invoice/${data.id}`, {
        patient,
        appointment,
        product,
        notes,
        total: grandTotal,
      });
    }else{
      var res = await post('invoice', {
        patient,
        appointment,
        product,
        notes,
        total: grandTotal,
      });
    }
    showToast(res.message, !res.status && 'error')
    setLoading(false)
    if (!res.status) {
      setErrors(res.errors)
      return;
    }
    toggle()
    refresh()
  }

  const appointmentFee = useMemo(() => {
    let data = appointmentData?.find(
      item => item.appointment.id == appointment
    )
    if (appointment && appointmentData && data) {
      if (data?.appointment?.meeting_type == "Follow-up Appointment") {
        return data?.appointment?.doctor.doctor.followup_fee
      }
      return data?.appointment?.doctor.doctor.initial_fee
    } else {
      return 0
    }
  }, [data, appointmentData])

  const grandTotal = useMemo(() => {
    let total = product.reduce((prev, next) => prev + next.price, 0)
    return +total + +appointmentFee
  })

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Invoice" : "Create Invoice"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-email-Input">Patient</Label>
                <Select
                  options={patientData}
                  onInputChange={e => handlePatient(e)}
                  onChange={e => setPatient(e.value)}
                  isLoading={loading}
                  defaultValue={
                    data
                      ? { label: data.user.fullname, value: data.user.id }
                      : defaultPatient
                      ? defaultPatient
                      : null
                  }
                />
                {errors?.patient && (
                  <span className="form-text text-danger">
                    {errors?.patient[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-password-Input">Appointment</Label>
                <Select
                  options={appointmentData.concat({
                    label: "Other",
                    value: "0",
                  })}
                  onInputChange={e => handleAppointment(e)}
                  onFocus={() => handleAppointment()}
                  onChange={e => setAppointment(e.value)}
                  isLoading={loading}
                  classNamePrefix="select2-selection"
                  defaultValue={
                    data && {
                      label:
                        data.appointment_id == 0
                          ? "Other"
                          : data.appointment?.client?.fullname +
                            " | " +
                            data.appointment?.doctor?.fullname +
                            " | " +
                            formatDate(data.date),
                      value: data.appointment_id == 0 ? 0 : data.user?.id,
                    }
                  }
                />
                {errors?.appointment && (
                  <span className="form-text text-danger">
                    {errors?.appointment[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <div className="table-responsive">
            <Table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th width="50%">SKU</th>
                  <th width="50%">Item</th>
                  <th width="25%">Unit Price</th>
                </tr>
              </thead>
              <tbody>
                {appointment ? (
                  <tr>
                    <td>Appointment</td>
                    <td>Appointment Fee</td>
                    <td>${appointmentFee}</td>
                    <td></td>
                  </tr>
                ) : null}
                {product?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <Select
                        options={productData ?? []}
                        onChange={e => onSelectProduct(e.value, i)}
                        isLoading={loading}
                        defaultValue={
                          data && { label: item?.sku, value: item?.id }
                        }
                      />
                    </td>
                    <td>{item?.name ?? "-"}</td>
                    <td>${item?.price ?? "-"}</td>
                    <td>
                      <div
                        className="btn btn-trigger"
                        onClick={() =>
                          setProduct(product.filter(prod => prod.id != item?.id))
                        }
                      >
                        <i className="bx bx-trash"></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="mt-3">
            <Row className="d-md-flex justify-content-md-between">
              <Col md={6}>
                <a
                  type="button"
                  className="btn btn-primary w-md"
                  onMouseDown={() => addProduct()}
                >
                  <span>Add Item</span>
                </a>
              </Col>
              <Col md={6}>
                <Table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>${grandTotal}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Label htmlFor="notes">Notes</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Message"
              id="notes"
              value={notes}
              onInput={e => setNotes(e.target.value)}
            />
            {errors?.notes && (
              <span className="form-text text-danger">{errors?.notes[0]}</span>
            )}
          </div>
          <div className="d-md-flex justify-content-md-end">
            <button
              type="submit"
              className="btn btn-primary w-md"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

CreateUpdateInvoice.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
  defaultPatient: PropTypes.any,
};

export default React.memo(CreateUpdateInvoice)