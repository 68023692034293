import React from 'react'
import PropTypes from "prop-types";
import { Modal, Spinner } from 'reactstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CardComponent from './CardComponent';
import api from 'helpers/api';
import { useQuery } from '@tanstack/react-query';

// const stripePromise = loadStripe('pk_live_51JrhmkEabK4QG6eiQzCfQSEpR6WksEskYjRxETM0cIcwTg2SPNBXfnyZFx8WInPGIRokrNIqvJOrdd3NQpHr9SFm00cpALwk0q');

const Payment = ({ data, modal, toggle, refresh }) => {
  const { data: stripe, isLoading } = useQuery(['get-stripe-key'], () => api.getStripeKey())

  let stripePromise
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(stripe.stripe_key)
    }
    return stripePromise
  }

  // const handleSubmit = async() => {
  //   setLoading(true);
  //   if (data) {
  //     var res = await put(`appointment/${data.id}`, {
  //       date,
  //       patient,
  //       doctor,
  //       meeting,
  //       message,
  //     });
  //   }else{
  //     var res = await post('appointment', {
  //       date,
  //       patient,
  //       doctor,
  //       meeting,
  //       message,
  //     });
  //   }
  //   showToast(res.message, !res.status && 'error')
  //   setLoading(false)
  //   if (!res.status) {
  //     setErrors(res.errors)
  //     return;
  //   }
  //   toggle()
  //   refresh()
  // }
  return (
    <Modal size="lg" toggle={() => toggle()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Invoice Payment</h5>
        <button
          onClick={() => toggle()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading ? (
          <Spinner className="ms-2 spinner-loading" color="success" />
        ) : (
          <div className="p-4 border">
            <Elements stripe={getStripe()}>
              <CardComponent data={data} toggle={toggle} refresh={refresh} />
            </Elements>
          </div>
        )}
      </div>
    </Modal>
  )
}

Payment.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  refresh: PropTypes.func,
  toggle: PropTypes.func,
};

export default Payment;