import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { Col, Input, Label, Row } from "reactstrap"
import Editor from "components/Common/Editor"

const EmailTemplate = ({ data, refetch, errors }) => {
  const { setValue } = useFormContext()

  return (
    <>
      <Row>
        <Col md={3}>
          <div className="mb-3">
            <Label>Appointment First Reminder Value</Label>
            <Input
              type="number"
              className="form-control"
              placeholder="Interval"
              onInput={e =>
                setValue("appointment_first_reminder_value", e.target.value)
              }
              defaultValue={data?.appointment_first_reminder_value}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <Label>Appointment First Reminder Interval</Label>
            <select
              className="form-control"
              defaultValue={data?.appointment_first_reminder_interval}
              onInput={e =>
                setValue("appointment_first_reminder_interval", e.target.value)
              }
            >
              <option value="">Choose Interval</option>
              <option value="Days">Days</option>
              <option value="Hours">Hours</option>
              <option value="Minutes">Minutes</option>
            </select>
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <Label>Appointment Second Reminder Value</Label>
            <Input
              type="number"
              className="form-control"
              placeholder="Interval"
              onInput={e =>
                setValue("appointment_second_reminder_value", e.target.value)
              }
              defaultValue={data?.appointment_second_reminder_value}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="mb-3">
            <Label>Appointment Second Reminder Interval</Label>
            <select
              className="form-control"
              defaultValue={data?.appointment_second_reminder_interval}
              onInput={e =>
                setValue("appointment_second_reminder_interval", e.target.value)
              }
            >
              <option value="">Choose Interval</option>
              <option value="Days">Days</option>
              <option value="Hours">Hours</option>
              <option value="Minutes">Minutes</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="mb-3">
            <Label>Appointment Reminder Template</Label>
            <Editor
              onChange={(event, editor) => {
                setValue("appointment_reminder", editor.getData())
              }}
              data={data?.appointment_reminder ?? ""}
            />
            {errors?.appointment_reminder && (
              <span className="form-text text-danger">
                {errors?.appointment_reminder[0]}
              </span>
            )}
          </div>
        </Col>
        {/* <Col md={6}>
          <div className="mb-3">
            <Label>Stripe Private Key</Label>
            <Editor
              onChange={(event, editor) => {
                setValue("appointment_reminder", editor.getData())
              }}
              data={data?.appointment_reminder ?? ""}
            />
            {errors?.appointment_reminder && (
              <span className="form-text text-danger">
                {errors?.appointment_reminder[0]}
              </span>
            )}
          </div>
        </Col> */}
      </Row>
      <div>
        Template Rules:
        <ul>
          <li>Patient Name: [patient_name]</li>
          <li>Appointment Date: [appointment_date]</li>
          <li>Appointment Time: [appointment_time]</li>
          <li>Appointment Doctor: [appointment_doctor]</li>
          <li>Appointment Type: [appointment_type]</li>
          <li>Clinic Name: [app_name]</li>
          <li>Clinic Email: [app_email]</li>
          <li>Clinic Phone: [app_phone]</li>
        </ul>
        <small className="text-danger">* Use with bracket</small>
      </div>
    </>
  )
}

EmailTemplate.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
}

export default React.memo(EmailTemplate)
