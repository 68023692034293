import React, { useState } from "react"
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useQueries } from "@tanstack/react-query";
import api from "helpers/api";

const PersonalDetail = ({ data, refetch, errors, disableInput = false }) => {
  const { setValue } = useFormContext();
  const [dataType, setDataType] = useState([])
  const [dataTypeProblem, setDataTypeProblem] = useState([])

  const results = useQueries({
    queries: [
      {
        queryKey: ['patient-create-update', 1], queryFn: () => api.getPatientType(), onSuccess: (res) => {
          setDataType(res)
        }
      },
      {
        queryKey: ['patient-create-update', 2], queryFn: () => api.getPatientProblem(), onSuccess: (res) => {
          setDataTypeProblem(res)
        }
      },
    ]
  })
  return (
    <>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-first-name-Input">First Name</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-first-name-Input"
              placeholder="First Name"
              defaultValue={data?.user?.name}
              onInput={(e) => setValue('name', e.target.value)}
              disabled={disableInput}
            />
            {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-last-name-Input">Last Name</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-last-name-Input"
              placeholder="Last Name"
              defaultValue={data?.user.last_name}
              onInput={(e) => setValue('last_name', e.target.value)}
              disabled={disableInput}
            />
            {errors.last_name && <span className="form-text text-danger">{errors.last_name[0]}</span>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-email-Input">Email</Label>
            <Input
              type="email"
              className="form-control"
              id="formrow-email-Input"
              placeholder="Email"
              defaultValue={data?.user.email}
              onInput={(e) => setValue('email', e.target.value)}
              disabled={disableInput}
            />
            {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-phone-Input">Phone</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-phone-Input"
              placeholder="Phone"
              defaultValue={data?.user.phone}
              onInput={(e) => setValue('phone', e.target.value)}
              disabled={disableInput}
            />
            {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-dob-Input">Date of Birth</Label>
            <Input
              type="date"
              className="form-control"
              id="formrow-dob-Input"
              placeholder="Date of Birth"
              defaultValue={data?.b_date}
              onInput={(e) => setValue('b_date', e.target.value)}
              disabled={disableInput}
            />
            {errors.b_date && <span className="form-text text-danger">{errors.b_date[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-Gender">Gender</Label>
            <select
              id="formrow-Gender"
              className="form-control"
              defaultValue={data?.gender}
              onInput={(e) => setValue('gender', e.target.value)}
              disabled={disableInput}
            >
              <option value="">Choose...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {errors.gender && <span className="form-text text-danger">{errors.gender[0]}</span>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-dob-Input">Patient Type</Label>
            <select
              id="formrow-PatientType"
              className="form-control"
              defaultValue={data?.type_patients_id}
              onInput={(e) => setValue('patient_type', e.target.value)}
              disabled={disableInput}
            >
              <option>Choose...</option>
              {dataType.map(item => (
                <option value={item.value} key={item.value} selected={item.value == data?.type_patients_id}>{item.label}</option>
              ))}
            </select>
            {errors.patient_type && <span className="form-text text-danger">{errors.patient_type[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-PatientTypeProblem">Patient Presenting Problem</Label>
            <select
              id="formrow-PatientTypeProblem"
              className="form-control"
              defaultValue={data?.type_presenting_problem_id}
              onInput={(e) => setValue('patient_type_problem', e.target.value)}
              disabled={disableInput}
            >
              <option>Choose...</option>
              {dataTypeProblem.map(item => (
                <option value={item.value} key={item.value} selected={item.value == data?.type_presenting_problem_id}>{item.label}</option>
              ))}
            </select>
            {errors.patient_type_problem && <span className="form-text text-danger">{errors.patient_type_problem[0]}</span>}
          </div>
        </Col>
      </Row>

      <div className="mb-3">
        <Label htmlFor="formrow-Address">Address</Label>
        <Input
          type="text"
          className="form-control"
          placeholder="Address"
          id="formrow-Address"
          defaultValue={data?.address}
          onInput={(e) => setValue('address', e.target.value)}
          disabled={disableInput}
        />
        {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
      </div>

      <Row>
        {/* <Col md={4}>
          <div className="mb-3">
            <Label htmlFor="formrow-irn-number-Input">IRN Number</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-irn-number-Input"
              placeholder="IRN Number"
              defaultValue={data?.irn_number}
              onInput={(e) => setValue('irn_number', e.target.value)}
              disabled={disableInput}
            />
            {errors.irn_number && <span className="form-text text-danger">{errors.irn_number[0]}</span>}
          </div>
        </Col> */}
        {/* <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-medicare-number-Input">Medicare Number</Label>
            <Input
              type="text"
              className="form-control"
              id="formrow-Medicare Number-Input"
              placeholder="Medicare Number"
              defaultValue={data?.medicare_number}
              onInput={(e) => setValue('medicare_number', e.target.value)}
              disabled={disableInput}
            />
            {errors.medicare_number && <span className="form-text text-danger">{errors.medicare_number[0]}</span>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-medicare-exp-Input">Medicare Expired</Label>
            <Input
              type="month"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
              max={new Date().getFullYear() + '-12-31'}
              className="form-control"
              id="formrow-medicare-exp-Input"
              placeholder="Medicare Expired"
              defaultValue={data?.medicare_expired}
              onInput={(e) => setValue('medicare_expired', e.target.value)}
              disabled={disableInput}
            />
            {errors.medicare_expired && <span className="form-text text-danger">{errors.medicare_expired[0]}</span>}
          </div>
        </Col> */}
      </Row>
    </>
  )
}

PersonalDetail.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  errors: PropTypes.object,
  disableInput: PropTypes.bool,
};

export default React.memo(PersonalDetail);