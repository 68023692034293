import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { showToast } from 'helpers/util';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';

const CreateUpdateQuestionary = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { getValues, setValue, handleSubmit, watch, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });
  const { mutate } = useMutation((params) => {
    return data ? api.updateQuestionary(params) : api.addQuestionary(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      setLoading(false)
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit questionary', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    }else{
      setValue('id', null)
    }
    reset(data ?? {});
    setErrors([])
  }, [data])

  const onSubmit = () => mutate(getValues());

  return (
    <Modal toggle={() => handleCreateModal()} isOpen={modal} centered size="md" contentClassName="h-unset">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Questionary" : "Create Questionary"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Question</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Question"
                  defaultValue={data?.question}
                  onChange={e => setValue("question", e.target.value)}
                />
                {errors?.question && (
                  <span className="form-text text-danger">
                    {errors?.question[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Answer Type</Label>
                <select
                  id="formrow-type"
                  className="form-control"
                  defaultValue={data?.type}
                  onInput={e => setValue("type", e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="rate">Rate 1 - 10</option>
                  <option value="essay">Essay</option>
                </select>
                {errors?.type && (
                  <span className="form-text text-danger">
                    {errors?.type[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          {watch("type") == "rate" ? (
            <Row>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-address">Lowest Option</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="formrow-address"
                    placeholder="Lowest Option"
                    defaultValue={data?.low_option_name}
                    onChange={e => setValue("low_option_name", e.target.value)}
                  />
                  {errors?.low_option_name && (
                    <span className="form-text text-danger">
                      {errors?.low_option_name[0]}
                    </span>
                  )}
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <Label htmlFor="formrow-address">Highest Option</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="formrow-address"
                    placeholder="Highest Option"
                    defaultValue={data?.high_option_name}
                    onChange={e => setValue("high_option_name", e.target.value)}
                  />
                  {errors?.high_option_name && (
                    <span className="form-text text-danger">
                      {errors?.high_option_name[0]}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit(onSubmit())
            }}
          >
            {loading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateQuestionary.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateQuestionary);