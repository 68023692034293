import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { post, put } from 'helpers/api_helper';
import { showToast, toFormdata } from 'helpers/util';
import Select from "react-select";
import api from 'helpers/api';
import classnames from 'classnames';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Editor from 'components/Common/Editor';
import ReactSelect from 'react-select';

const CreateUpdateDoctor = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [roles, setRoles] = useState([])
  const [problems, setProblems] = useState([])
  const [specialists, setSpecialists] = useState([])
  const [errors, setErrors] = useState([])
  const results = useQueries({
    queries: [
      {
        queryKey: ["doctor-create-update", 1],
        queryFn: () => api.getRoles(),
        onSuccess: res => {
          setRoles(res)
        },
      },
      {
        queryKey: ["type-problems", 1],
        queryFn: () => api.selectPatientProblem(),
        onSuccess: res => {
          setProblems(res)
        },
      },
      {
        queryKey: ["select-specialist", 1],
        queryFn: () => api.selectSpecialist(),
        onSuccess: res => {
          setSpecialists(res)
        },
      },
    ],
  })

  const { data: brands, isLoading: loadingBrand } = useQuery(
    ["select-brands"],
    () => api.selectBrand()
  )

  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return data ? api.updateDoctor(params, getValues('id')) : api.addDoctor(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit doctor', 'error')
    }
  })

  const onSubmit = () => {
    mutate(toFormdata(getValues(), data ? true : false))
  }
  // const onSubmit = () => mutate(getValues());

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    } else {
      setValue('id', null)
    }
    reset({
      ...data,
      ...data?.doctor,
      problems: data?.problems?.map(item => item.id),
      specialist: data?.specialist.id,
      emergency_name: data?.user.emergency_contact?.first_name,
      emergency_last_name: data?.user.emergency_contact?.last_name,
      emergency_email: data?.user.emergency_contact?.email,
      emergency_phone: data?.user.emergency_contact?.phone,
      emergency_relationship: data?.user.emergency_contact?.relationship,
      status: data?.status,
    } ?? {});
    setErrors([])
  }, [data])

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Doctor" : "Create Doctor"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col md="3">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Emergency Contact
                  </NavLink>
                </NavItem>
                {data && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleVertical("3")
                      }}
                    >
                      Password
                    </NavLink>
                  </NavItem>
                )}
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Roles
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Billing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    Meetings Link
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleVertical("8")
                    }}
                  >
                    Appointment Settings
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="9">
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={data?.doctor.name}
                          onInput={e => setValue("name", e.target.value)}
                        />
                        {errors.name && (
                          <span className="form-text text-danger">
                            {errors.name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.doctor.last_name}
                          onInput={e => setValue("last_name", e.target.value)}
                        />
                        {errors.last_name && (
                          <span className="form-text text-danger">
                            {errors.last_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Email</Label>
                        <Input
                          type="search"
                          className="form-control"
                          id="formrow-email-Input"
                          placeholder="Email"
                          defaultValue={data?.doctor.email}
                          autoComplete="off"
                          onInput={e => setValue("email", e.target.value)}
                        />
                        {errors.email && (
                          <span className="form-text text-danger">
                            {errors.email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-phone-Input">
                          Personal Email
                        </Label>
                        <Input
                          type="search"
                          className="form-control"
                          id="formrow-personal-email-Input"
                          placeholder="Personal Email"
                          defaultValue={data?.personal_email}
                          autoComplete="off"
                          onInput={e =>
                            setValue("personal_email", e.target.value)
                          }
                        />
                        {errors.personal_email && (
                          <span className="form-text text-danger">
                            {errors.personal_email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-phone-Input">Phone</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.doctor.phone}
                          onInput={e => setValue("phone", e.target.value)}
                        />
                        {errors.phone && (
                          <span className="form-text text-danger">
                            {errors.phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-peronal-phone-Input">
                          Personal Phone
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-peronal-phone-Input"
                          placeholder="Personal Phone"
                          defaultValue={data?.personal_phone}
                          onInput={e =>
                            setValue("personal_phone", e.target.value)
                          }
                        />
                        {errors.personal_phone && (
                          <span className="form-text text-danger">
                            {errors.personal_phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  {!data && (
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Password
                          </Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Password"
                            autoComplete="new-password"
                            onInput={e => setValue("password", e.target.value)}
                          />
                          {errors.password && (
                            <span className="form-text text-danger">
                              {errors.password[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password_confirmation-Input">
                            Password Confirmation
                          </Label>
                          <Input
                            type="password"
                            className="form-control"
                            id="formrow-password_confirmation-Input"
                            placeholder="Password Confirmation"
                            autoComplete="new-password"
                            onInput={e =>
                              setValue("password_confirmation", e.target.value)
                            }
                          />
                          {errors.password_confirmation && (
                            <span className="form-text text-danger">
                              {errors.password_confirmation[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Date of Birth</Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="formrow-dob-Input"
                          placeholder="Date of Birth"
                          defaultValue={data?.doctor.b_date}
                          onInput={e => setValue("b_date", e.target.value)}
                        />
                        {errors.b_date && (
                          <span className="form-text text-danger">
                            {errors.b_date[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Gender">Gender</Label>
                        <select
                          id="formrow-Gender"
                          className="form-control"
                          defaultValue={data?.gender}
                          onInput={e => setValue("gender", e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.gender && (
                          <span className="form-text text-danger">
                            {errors.gender[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="mb-3">
                    <Label htmlFor="formrow-Address">Address</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      id="formrow-Address"
                      defaultValue={data?.address}
                      onInput={e => setValue("address", e.target.value)}
                    />
                    {errors.address && (
                      <span className="form-text text-danger">
                        {errors.address[0]}
                      </span>
                    )}
                  </div>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-permissions-Input">
                          Problems
                        </Label>
                        <Select
                          options={problems}
                          isMulti
                          onChange={e => {
                            setValue(
                              "problems",
                              e.map(item => item.value)
                            )
                          }}
                          classNamePrefix="select2-selection"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                          }}
                          defaultValue={
                            data &&
                            data.problems.map(item => ({
                              label: item.name_problem,
                              value: item.id,
                            }))
                          }
                        />
                        {errors.problems && (
                          <span className="form-text text-danger">
                            {errors.problems[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <input type="checkbox" name="add_essay" value={1} className="mb-3" onInput={(e) => {
                    setValue('add_essay', e.target.checked)
                  }} defaultChecked={!!data?.add_essay} /> Add Essay

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Gender">Specialist</Label>
                        <select
                          id="formrow-Gender"
                          className="form-control"
                          defaultValue={data?.specialist.id}
                          onInput={e => setValue("specialist", e.target.value)}
                        >
                          <option value="">Choose...</option>
                          {specialists.map(specialist => (
                            <option value={specialist.value} key={specialist.value}>{specialist.label}</option>
                          ))}
                        </select>
                        {errors.specialist && (
                          <span className="form-text text-danger">
                            {errors.specialist[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-name">Brand</Label>
                        <ReactSelect
                          options={brands ?? []}
                          isMulti
                          isLoading={loadingBrand}
                          onChange={e =>
                            setValue(
                              "brands",
                              e.map(val => val.value)
                            )
                          }
                          defaultValue={brands?.filter(ids =>
                            data?.brands
                              ?.map(brand => brand.id)
                              .includes(ids.value)
                          )}
                        />
                        {errors?.brands && (
                          <span className="form-text text-danger">
                            {errors?.brands[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Coupon">Coupon</Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Coupon"
                          id="formrow-Coupon"
                          defaultValue={data?.coupon}
                          onInput={e => setValue("coupon", e.target.value)}
                          maxLength={8}
                        />
                        {errors.coupon && (
                          <span className="form-text text-danger">
                            {errors.coupon[0]}
                          </span>
                        )}
                        <small>Max 8 characters</small>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-photo-Input">Photo</Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="formrow-photo-Input"
                          placeholder="Photo"
                          defaultValue={data?.photo}
                          onInput={e => setValue("photo", e.target.files[0])}
                        />
                        {errors.photo && (
                          <span className="form-text text-danger">
                            {errors.photo[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="formrow-description-Input">
                          Profile Description
                        </Label>
                        <Editor
                          onChange={(event, editor) => {
                            setValue("description", editor.getData())
                          }}
                          data={data?.description ?? ""}
                        />
                        {errors.description && (
                          <span className="form-text text-danger">
                            {errors.description[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-status-Input">Status</Label>
                        <select
                          id="formrow-status-Input"
                          className="form-control"
                          defaultValue={data?.status}
                          onInput={e => setValue("status", e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="ACTIVE">ACTIVE</option>
                          <option value="INACTIVE">INACTIVE</option>
                        </select>
                        {errors.status && (
                          <span className="form-text text-danger">
                            {errors.status[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={
                            data?.user.emergency_contact?.first_name
                          }
                          onInput={e =>
                            setValue("emergency_name", e.target.value)
                          }
                        />
                        {errors.emergency_name && (
                          <span className="form-text text-danger">
                            {errors.emergency_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.user.emergency_contact?.last_name}
                          onInput={e =>
                            setValue("emergency_last_name", e.target.value)
                          }
                        />
                        {errors.emergency_last_name && (
                          <span className="form-text text-danger">
                            {errors.emergency_last_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-phone-Input">
                          Phone
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-emergency-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.user.emergency_contact?.phone}
                          onInput={e =>
                            setValue("emergency_phone", e.target.value)
                          }
                        />
                        {errors.emergency_phone && (
                          <span className="form-text text-danger">
                            {errors.emergency_phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-email-Input">
                          Email
                        </Label>
                        <Input
                          type="search"
                          autoComplete="off"
                          className="form-control"
                          id="formrow-emergency-email-Input"
                          placeholder="Email"
                          defaultValue={data?.user.emergency_contact?.email}
                          onInput={e =>
                            setValue("emergency_email", e.target.value)
                          }
                        />
                        {errors.emergency_email && (
                          <span className="form-text text-danger">
                            {errors.emergency_email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Relationship-Input">
                          Relationship
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-Relationship-Input"
                          placeholder="Relationship"
                          defaultValue={
                            data?.user.emergency_contact?.relationship
                          }
                          onInput={e =>
                            setValue("emergency_relationship", e.target.value)
                          }
                        />
                        {errors.emergency_relationship && (
                          <span className="form-text text-danger">
                            {errors.emergency_relationship[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">Password</Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password-Input"
                          placeholder="Password"
                          onChange={e => setValue("password", e.target.value)}
                        />
                        {errors.password && (
                          <span className="form-text text-danger">
                            {errors.password[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password_confirmation-Input">
                          Password Confirmation
                        </Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password_confirmation-Input"
                          placeholder="Password Confirmation"
                          onChange={e =>
                            setValue("password_confirmation", e.target.value)
                          }
                        />
                        {errors.password_confirmation && (
                          <span className="form-text text-danger">
                            {errors.password_confirmation[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId="5">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-permissions-Input">
                          Special Permissions
                        </Label>
                        <Select
                          options={roles?.roles}
                          isMulti
                          onInput={e => setValue("permissions", e.value)}
                          // defaultValue={data && { label: data.client.fullname, value: data.client.id }}
                        />
                        {errors.roles && (
                          <span className="form-text text-danger">
                            {errors.roles[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane> */}
                <TabPane tabId="6">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-admin-fee-Input">
                          Admin Fee (%)
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-admin-fee-Input"
                          placeholder="Admin Fee"
                          defaultValue={data?.admin_fee}
                          onInput={e => setValue("admin_fee", e.target.value)}
                        />
                        {errors.admin_fee && (
                          <span className="form-text text-danger">
                            {errors.admin_fee[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-booking-cancellation-fee-Input">
                          Booking Cancellation Fee (%)
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-booking-cancellation-fee-Input"
                          placeholder="Booking Cancellation Fee"
                          defaultValue={data?.cancel_fee}
                          onInput={e => setValue("cancel_fee", e.target.value)}
                        />
                        {errors.cancel_fee && (
                          <span className="form-text text-danger">
                            {errors.cancel_fee[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="7">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-ms-team-link-Input">
                          Microsoft Teams Link
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-ms-team-link-Input"
                          placeholder="Microsoft Teams Link"
                          defaultValue={data?.ms_team_link}
                          onInput={e =>
                            setValue("ms_team_link", e.target.value)
                          }
                        />
                        {errors.ms_team_link && (
                          <span className="form-text text-danger">
                            {errors.ms_team_link[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-ms-team-id-Input">
                          Microsoft Teams ID
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-ms-team-id-Input"
                          placeholder="Microsoft Teams ID"
                          defaultValue={data?.ms_team_id}
                          onInput={e => setValue("ms_team_id", e.target.value)}
                        />
                        {errors.ms_team_id && (
                          <span className="form-text text-danger">
                            {errors.ms_team_id[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-ms-team-passcode-Input">
                          Microsoft Teams Passcode
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-ms-team-passcode-Input"
                          placeholder="Microsoft Teams Passcode"
                          defaultValue={data?.ms_team_passcode}
                          onInput={e =>
                            setValue("ms_team_passcode", e.target.value)
                          }
                        />
                        {errors.ms_team_passcode && (
                          <span className="form-text text-danger">
                            {errors.ms_team_passcode[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-zoom-link-Input">
                          Zoom Meeting Link
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-zoom-link-Input"
                          placeholder="Zoom Meeting Link"
                          defaultValue={data?.zoom_link}
                          onInput={e => setValue("zoom_link", e.target.value)}
                        />
                        {errors.zoom_link && (
                          <span className="form-text text-danger">
                            {errors.zoom_link[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-zoom-id-Input">Zoom ID</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-zoom-id-Input"
                          placeholder="Zoom ID"
                          defaultValue={data?.zoom_id}
                          onInput={e => setValue("zoom_id", e.target.value)}
                        />
                        {errors.zoom_id && (
                          <span className="form-text text-danger">
                            {errors.zoom_id[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-zoom-passcode-Input">
                          Zoom Passcode
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-zoom-passcode-Input"
                          placeholder="Zoom ID"
                          defaultValue={data?.zoom_passcode}
                          onInput={e =>
                            setValue("zoom_passcode", e.target.value)
                          }
                        />
                        {errors.zoom_passcode && (
                          <span className="form-text text-danger">
                            {errors.zoom_passcode[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="8">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-initial-appointment">
                          Initial Appointment
                        </Label>
                        <select
                          id="formrow-initial-appointment"
                          className="form-control"
                          defaultValue={data?.initial_appointment}
                          onInput={e =>
                            setValue("initial_appointment", e.target.value)
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="15">15 Minutes</option>
                          <option value="30">30 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="60">60 Minutes</option>
                          <option value="75">75 Minutes</option>
                          <option value="90">90 Minutes</option>
                          <option value="105">105 Minutes</option>
                          <option value="120">120 Minutes</option>
                        </select>
                        {errors.initial_appointment && (
                          <span className="form-text text-danger">
                            {errors.initial_appointment[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-followup-appointment">
                          Follow-up Appointment
                        </Label>
                        <select
                          id="formrow-followup-appointment"
                          className="form-control"
                          defaultValue={data?.followup_appointment}
                          onInput={e =>
                            setValue("followup_appointment", e.target.value)
                          }
                        >
                          <option value="">Choose...</option>
                          <option value="15">15 Minutes</option>
                          <option value="30">30 Minutes</option>
                          <option value="45">45 Minutes</option>
                          <option value="60">60 Minutes</option>
                          <option value="75">75 Minutes</option>
                          <option value="90">90 Minutes</option>
                          <option value="105">105 Minutes</option>
                          <option value="120">120 Minutes</option>
                        </select>
                        {errors.followup_appointment && (
                          <span className="form-text text-danger">
                            {errors.followup_appointment[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-admin-fee-Input">
                          Initial Appointment Fee ($)
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-admin-fee-Input"
                          placeholder="Initial Appointment Fee"
                          defaultValue={data?.initial_fee}
                          onInput={e => setValue("initial_fee", e.target.value)}
                        />
                        {errors.initial_fee && (
                          <span className="form-text text-danger">
                            {errors.initial_fee[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-booking-cancellation-fee-Input">
                          Follow-up Appointment Fee ($)
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="formrow-booking-cancellation-fee-Input"
                          placeholder="Follow-up Appointment Fee"
                          defaultValue={data?.followup_fee}
                          onInput={e =>
                            setValue("followup_fee", e.target.value)
                          }
                        />
                        {errors.followup_fee && (
                          <span className="form-text text-danger">
                            {errors.followup_fee[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={() => handleSubmit(onSubmit())}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateDoctor.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateDoctor);