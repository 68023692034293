import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Form, Input, Label, Modal, Row } from "reactstrap"
import { showToast } from "helpers/util"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import api from "helpers/api"

const CreateUpdateBrand = ({
  data,
  toggle,
  modal,
  handleCreateModal,
  refresh,
}) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const {
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { formErrors },
  } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    },
  })
  const { mutate } = useMutation(
    params => {
      return data ? api.updateBrand(data.id, params) : api.addBrand(params)
    },
    {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        setLoading(false)
        if (!res.status) {
          setErrors(res.errors)
          return
        }
        toggle()
        refresh()
      },
      onError: err => {
        showToast("Failed to submit brand", "error")
      },
    }
  )

  useEffect(() => {
    if (data) {
      setValue("id", data.id)
    } else {
      setValue("id", null)
    }
    reset(data ?? {})
    setErrors([])
  }, [data])

  const onSubmit = () => mutate(getValues())

  return (
    <Modal toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Brand" : "Create Brand"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Name"
                  defaultValue={data?.name}
                  onChange={e => setValue("name", e.target.value)}
                />
                {errors?.name && (
                  <span className="form-text text-danger">
                    {errors?.name[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit(onSubmit())
            }}
          >
            {loading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateBrand.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
}

export default React.memo(CreateUpdateBrand)
