import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { getRole } from "helpers/util"

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {(getRole() == "Admin" ||
              getRole() == "Staff" ||
              getRole() == "Doctor") && (
              <li>
                <Link to="/#" className="">
                  <i className="bx bx-home-circle"></i>
                  {/* <span className="badge rounded-pill bg-info float-end">
                    04
                  </span> */}
                  <span>{props.t("Dashboards")}</span>
                </Link>
              </li>
            )}

            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-notepad"></i>
                <span>Invoice</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/invoices">Invoice List</Link>
                </li>
                <li>
                  <Link to="#">Payment History</Link>
                </li>
              </ul>
            </li> */}

            {/* {getRole() != "Doctor" && (
              <li>
                <Link to="/invoices" className=" ">
                  <i className="bx bx-notepad"></i>
                  <span>Invoices</span>
                </Link>
              </li>
            )} */}

            {/* <li>
              <Link to="/appointments" className=" ">
                <i className="bx bx-calendar"></i>
                <span>Appointment</span>
              </Link>
            </li> */}

            {getRole() == "Doctor" && (
              <li>
                <Link to="/patients" className=" ">
                  <i className="bx bxs-user-detail"></i>
                  <span>Patient List</span>
                </Link>
              </li>
            )}

            {(getRole() == "Admin" || getRole() == "Staff") && (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-user-detail"></i>
                    <span>Patient</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/patients">Patient List</Link>
                    </li>
                    <li>
                      <Link to="/type-patients">Type Patient</Link>
                    </li>
                    <li>
                      <Link to="/type-problems">Type Problem</Link>
                    </li>
                    <li>
                      <Link to="/questionaries">Questionary</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to={`/pharmacies`} className=" ">
                    <i className="bx bx-plus-medical"></i>
                    <span>Pharmacy</span>
                  </Link>
                </li>
                <li>
                  <Link to={`/brands`} className=" ">
                    <i className="bx bx-plus-medical"></i>
                    <span>Brand</span>
                  </Link>
                </li>
              </>
            )}
            {getRole() == "Patient" && (
              <>
                <li>
                  <Link to="/wellness-program" className=" ">
                    <i className="bx bx-notepad"></i>
                    <span>Patient Wellness Program</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/patients/${
                      JSON.parse(localStorage.getItem("authUser")).user.id
                    }`}
                    className=" "
                  >
                    <i className="bx bx-plus-medical"></i>
                    <span>Profile</span>
                  </Link>
                </li>
              </>
            )}

            {(getRole() == "Admin" || getRole() == "Staff") && (
              <>
                {/* <li>
                  <Link to="/general-practices" className=" ">
                    <i className="bx bx-git-branch"></i>
                    <span>Practice Contacts</span>
                  </Link>
                </li> */}

                {getRole() == "Admin" && (
                  <React.Fragment>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bxs-user-badge"></i>
                        <span>User</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        {/* <li>
                          <Link to="/doctors" className=" ">
                            <i className="bx bx-plus-medical"></i>
                            <span>Doctor / Nurses</span>
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/external-doctors" className=" ">
                            <i className="bx bx-plus-medical"></i>
                            <span>External Doctor</span>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/schedules" className="">
                            <i className="bx bx-calendar"></i>
                            <span>Doctor Schedule</span>
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/users" className=" ">
                            <i className="bx bxs-user-badge"></i>
                            <span>User</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/specialists" className=" ">
                            <i className="bx bxs-user-badge"></i>
                            <span>Specialist</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bxs-report"></i>
                        <span>Report</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        <li>
                          <Link to="/report-doctor" className="">
                            <i className="bx bx-plus-medical"></i>
                            <span>Doctor</span>
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    <li>
                      <Link to="/settings" className="has-arrow">
                        <i className="bx bx-cog"></i>
                        <span>Settings</span>
                      </Link>
                      <ul className="sub-menu" aria-expanded="false">
                        {/* <li>
                          <Link to="/products" className=" ">
                            <i className="bx bx-list-ul"></i>
                            <span>Products</span>
                          </Link>
                        </li> */}
                        <li>
                          <Link to="/settings" className=" ">
                            <i className="bx bx-cog"></i>
                            <span>General Settings</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </React.Fragment>
                )}
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
