import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Form, Input, InputGroup, Label, Modal, Row, Spinner } from "reactstrap"
import { showToast, toFormdata } from "helpers/util"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import Select from "react-select"

const NewQuestionaryModal = ({ toggle, modal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [brands, setBrands] = useState(null)
  const {
    register,
    getValues,
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: 'e-script',
      value_type: "Document",
    },
  })

  const { data, isLoading } = useQuery(
    ["questionary-question"],
    () => api.getQuestionaryQuestion(),
    {
      onSuccess: res => {
        setValue(
          "question_ids",
          res.questions.map(item => item.id)
        )
      },
    }
  )

  const { data: pharmacy, isLoading: loadingPharmacy } = useQuery(
    ["select-pharmacy"],
    () => api.selectPharmacy()
  )

  const { mutate, isLoading: submitLoading } = useMutation(
    params => {
      return api.submitQuestionary(params)
    },
    {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        setLoading(false)
        if (!res.status) {
          showToast("Failed to submit questionary", "error")
          return
        }
        toggle()
        refresh()
      },
      onError: err => {
        showToast("Failed to submit pharmacy", "error")
      },
    }
  )

  const onSubmit = () => {
    if (!watch('pharmacy')) {
      return showToast('Pharmacy must be filled', 'error')
    }
    const user = JSON.parse(localStorage.getItem('authUser'))?.user
    const requirementId = user.requirement.find(
      item =>
        item.type == "e-script" && !item.answer
    )
    setValue("id", requirementId?.id)
    mutate(toFormdata(getValues()))
  }

  const { mutate: checkCoupon } = useMutation(params => api.checkCoupon(params), {
    onSuccess: (res) => {
      if (!res.status) {
        return showToast(res.message, 'error')
      }
      setValue('doctor_type', res.type)
      setValue('doctor', res.data.id)
      setBrands(res.data.brands)
      showToast('Coupon valid!')
    }
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.user
    const requirement = user.requirement.find(
      item =>
        item.type == "e-script" && !item.answer
    )
    if (user?.patient?.is_exist == 1 && !requirement) {
      setStep(0)
    }
  }, [])


  return (
    <Modal
      isOpen={modal}
      centered
      size="lg"
      contentClassName="h-unset"
      toggle={toggle}
    >
      <div className="modal-header">
        {step == "finish" ? (
          <h5 className="modal-title mt-0 mx-auto">Pharmacy</h5>
        ) : (
          <h5 className="modal-title mt-0">Upload Your Script</h5>
        )}
      </div>
      <div className="modal-body">
        {isLoading ? (
          <Spinner className="ms-2 spinner-loading" color="success" />
        ) : (
          <div id="form-wrapper" className="py-5">
            <form className="w-100">
              {step == 0 ? (
                data?.questions?.map((question, i) => (
                  <>
                    {question.type == "rate" ? (
                    <div key={question.id} className="my-3">
                      <h3 className="text-center mb-3">
                        Q{i + 1}. {question.question}
                      </h3>
                      <div id="debt-amount-slider">
                        <input
                          type="radio"
                          name={`question_${i}_0`}
                          id={`question_${i}_0`}
                          value="0"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_0`}
                          data-debt-amount="0"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_1`}
                          id={`question_${i}_1`}
                          value="1"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_1`}
                          data-debt-amount="1"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_2`}
                          id={`question_${i}_2`}
                          value="2"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_2`}
                          data-debt-amount="2"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_3`}
                          id={`question_${i}_3`}
                          value="3"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_3`}
                          data-debt-amount="3"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_4`}
                          id={`question_${i}_4`}
                          value="4"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_4`}
                          data-debt-amount="4"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_5`}
                          id={`question_${i}_5`}
                          value="5"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_5`}
                          data-debt-amount="5"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_6`}
                          id={`question_${i}_6`}
                          value="6"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_6`}
                          data-debt-amount="6"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_7`}
                          id={`question_${i}_7`}
                          value="7"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_7`}
                          data-debt-amount="7"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_8`}
                          id={`question_${i}_8`}
                          value="8"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_8`}
                          data-debt-amount="8"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}_9`}
                          id={`question_${i}_9`}
                          value="9"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_9`}
                          data-debt-amount="9"
                        ></label>
                        <input
                          type="radio"
                          name={`question_${i}1_0`}
                          id={`question_${i}_10`}
                          value="10"
                          {...register(`answer.${i}`, {
                            required: "This field is required",
                          })}
                        />
                        <label
                          htmlFor={`question_${i}_10`}
                          data-debt-amount="10"
                        ></label>
                        <div id="debt-amount-pos"></div>
                      </div>
                      <div className="d-flex justify-content-between mt-5">
                        <h4>{question.low_option_name}</h4>
                        <h4>{question.high_option_name}</h4>
                      </div>
                    </div>
                    ) : (
                    <div key={question.id}>
                      <h3 className="text-center mb-3">
                        Q{i + 1}. {question.question}
                      </h3>
                      <textarea
                        className="form-control"
                        id="formrow-description-Input"
                        placeholder="Answer"
                        onChange={e => setValue(`answer.${i}`, e.target.value)}
                        rows={5}
                      ></textarea>
                    </div>
                    )}
                    {errors.answer[i] && (
                      <span className="form-text text-danger">
                        {errors.answer[i].message}
                      </span>
                    )}
                  </>
                ))
              ) : step == 1 ? (
                <>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-files-Input">Type</Label>
                        <select
                          className="form-control"
                          {...register("value_type")}
                        >
                          <option value="Document">Document</option>
                          <option value="Link">Link</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  {watch("value_type") == "Document" ? (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-files-Input">Document</Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="formFile"
                            onChange={e => setValue("files", e.target.files[0])}
                          />
                          {errors.files && (
                            <span className="form-text text-danger">
                              {errors.files[0]}
                            </span>
                          )}
                          {Array.from({ length: 5 }).map(
                            (item, i) =>
                              errors[`files.${i}`] && (
                                <span className="form-text text-danger" key={i}>
                                  {errors[`files.${i}`][0]}
                                </span>
                              )
                          )}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-files-Input">Link</Label>
                          <input
                            className="form-control"
                            type="text"
                            id="formFile"
                            {...register("value")}
                          />
                          {errors.value && (
                            <span className="form-text text-danger">
                              {errors.value[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    {!watch("doctor") ? (
                      <Col md={12} className="mx-auto">
                        <div className="mb-3">
                          <Label htmlFor="doctor-coupon">Coupon</Label>
                          <InputGroup>
                            <Input
                              className="form-control"
                              type="text"
                              id="doctor-coupon"
                              placeholder="Coupon"
                              onChange={e => setValue("coupon", e.target.value)}
                            />
                            <button
                              className="btn btn-primary"
                              onClick={() => checkCoupon(getValues())}
                              type="button"
                            >
                              Check Coupon
                            </button>
                          </InputGroup>
                          {errors.coupon && (
                            <span className="form-text text-danger">
                              {errors.coupon[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    ) : null}
                    {brands ? (
                      <Col md={12} className="mx-auto">
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Select Brand
                          </Label>
                          <Select
                            options={brands}
                            onChange={e => {
                              setValue("doctor_brand", e.id)
                            }}
                            getOptionLabel={val => val.name}
                            getOptionValue={val => val.id}
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: base => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                          {errors.brands && (
                            <span className="form-text text-danger">
                              {errors.brands[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : (
                <Row>
                  {/* <Card>
                    <CardImg
                      alt="Card image cap"
                      src="https://picsum.photos/318/180"
                      top
                    />
                    <CardBody>
                      <CardTitle tag="h5">Card title</CardTitle>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        Card subtitle
                      </CardSubtitle>
                      <CardText>
                        This is a wider card with supporting text below as a
                        natural lead-in to additional content. This content is a
                        little bit longer.
                      </CardText>
                    </CardBody>
                  </Card> */}
                  <Col md={6} className="mx-auto">
                    <div className="mb-3">
                      <Label htmlFor="formrow-email-Input">
                        Select Pharmacy
                      </Label>
                      <Select
                        options={pharmacy}
                        onChange={e => setValue("pharmacy", e.value)}
                        defaultValue={null}
                        isLoading={loadingPharmacy}
                      />
                      {errors.pharmacy && (
                        <span className="form-text text-danger">
                          {errors.pharmacy[0]}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </form>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div
          className={`w-100 d-md-flex justify-content-md-${
            step == 0 || step == 1 ? "end" : "between"
          }`}
        >
          {step == "finish" ? (
            <button
              type="button"
              className="btn btn-primary w-md"
              onClick={e => {
                e.preventDefault()
                return setStep(1)
              }}
            >
              Prev
            </button>
          ) : null}
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault()
              if (step == 0) {
                return setStep(1)
              } else if (step == 1) {
                return console.log(trigger())
                return setStep("finish")
              } else if (step == "finish") {
                return handleSubmit(onSubmit())
              }
            }}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>{step < data?.questions?.length ? "Next" : "Submit"}</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

NewQuestionaryModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
}

export default React.memo(NewQuestionaryModal)
