import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Invoice from "../pages/Invoice/index"
import Appointment from "../pages/Appointment"
import Patient from "pages/Patient"
import Doctor from "pages/Doctor"
import Product from "pages/Product"
import Staff from "pages/Staff"
import PatientType from "pages/Patient/PatientType"
import PatientProblem from "pages/Patient/PatientProblem"
import DetailPatient from "pages/Patient/Detail/index"
import DetailInvoice from "pages/Invoice/DetailInvoice"
import NewBooking from "pages/NewBooking"
import Verify from "pages/Authentication/Verify"
import Schedule from "pages/Schedule"
import GeneralPractice from "pages/GeneralPractice"
import Setting from "pages/Setting"
import ReportDoctor from "pages/Report/ReportDoctor"
import Pharmacy from "pages/Pharmacy"
import Questionary from "pages/Questionary"
import WellnessProgram from "pages/WellnessProgram"
import Brand from "pages/Brand"
import ExternalDoctor from "pages/ExternalDoctor"
import Specialist from "pages/Specialist"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/type-problems", component: PatientProblem },
  { path: "/type-patients", component: PatientType },
  { path: "/patients", component: Patient },
  { path: "/doctors", component: Doctor },
  { path: "/products", component: Product },
  { path: "/users", component: Staff },
  { path: "/profile", component: UserProfile },
  { path: "/invoices", component: Invoice },
  { path: "/invoices/:id", component: DetailInvoice },
  { path: "/appointments", component: Appointment },
  { path: "/patients/:id", component: DetailPatient },
  { path: "/schedules", component: Schedule },
  // { path: "/general-practices", component: GeneralPractice },
  { path: "/external-doctors", component: ExternalDoctor },
  { path: "/brands", component: Brand },
  { path: "/pharmacies", component: Pharmacy },
  { path: "/questionaries", component: Questionary },
  { path: "/report-doctor", component: ReportDoctor },
  { path: "/settings", component: Setting },
  { path: "/specialists", component: Specialist },

  // //profile
  { path: "/wellness-program", component: WellnessProgram },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const patientRoutes = [
  { path: "/profile", component: UserProfile },
  { path: "/invoices", component: Invoice },
  { path: "/invoices/:id", component: DetailInvoice },
  { path: "/appointments", component: Appointment },
  { path: "/patients/:id", component: DetailPatient },
  { path: "/wellness-program", component: WellnessProgram },

  {
    path: "*",
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <Redirect
        to={`/patients/${JSON.parse(localStorage.getItem("authUser")).user.id}`}
      />
    ),
  },
  // {
  //   path: "*",
  //   exact: true,
  //   component: () => (
  //     <Redirect
  //       to={`/patients/${JSON.parse(localStorage.getItem("authUser")).user.id}`}
  //     />
  //   ),
  // },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/verify/:id", component: Verify },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/patient-booking", component: NewBooking },
]

export { publicRoutes, authProtectedRoutes, patientRoutes }
