import React, { useMemo, useState } from "react"
import { Card, CardBody, CardTitle, Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from "reactstrap"
import PropTypes from 'prop-types'
import { formatDate, getRole, getUrl, showToast } from "helpers/util"
import { get } from "helpers/api_helper"
import DocumentModal from "./DocumentModal"
import { useMutation } from "@tanstack/react-query"
import api from "helpers/api"
import DeleteConfirmation from "components/Alert/DeleteConfirmation"
import QuestionaryAnswer from "./QuestionaryAnswer"


const type = 'e-script';
const ScriptReminder = ({ data, refetch }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [modalData, setModalData] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [showModalAnswer, setShowModalAnswer] = useState(false);
  const [showDelete, setShowDelete] = useState(false)

  const { mutate: deleteDocument, isLoading: deleteLoading } = useMutation((params) => api.removeDocument(params), {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      refetch()
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const { mutate: sendQuestionary, isLoading: questionaryLoading } =
    useMutation(params => api.sendQuestionary(params), {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        refetch()
      },
      onError: err => {
        console.log(err)
        showToast("Failed to submit", "error")
      },
    })

  const document = useMemo(
    () =>
      data?.user?.requirement?.filter(item => item.type == type),
    [data]
  )
  const toggleModal = () => {
    if (showModal) {
      setShowModal(false)
    } else {
      setShowModal(true)
    }
  }

  const toggleAnswer = () => {
    if (document?.length == 5) {
      showToast("Maximum upload is 5 file", "error")
    }
    if (showModalAnswer) {
      setShowModalAnswer(false)
    } else {
      setShowModalAnswer(true)
    }
  }

  const onDelete = () => {
    deleteDocument({
      id: selectedId
    })
  };

  return (
    <React.Fragment>
      <Card style={{ height: 400 }}>
        <CardBody>
          <div className="d-md-flex justify-content-between">
            <CardTitle className="mb-4">E-Script</CardTitle>
            <Button
              size="sm"
              color="primary"
              style={{ height: 30 }}
              onClick={toggleModal}
            >
              Add E-Script
            </Button>
          </div>
          <Row>
            <Col>
              <div className="table-responsive mh-300 overflow-scroll">
                <Table bordered>
                  <thead className="table-light">
                    <tr>
                      <th>Value</th>
                      <th className="text-center">Exp</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {document?.length > 0
                      ? document?.map((item, key) =>
                          item ? (
                            <tr key={key}>
                              <td>
                                {item?.value.startsWith("https://") ? (
                                  <a
                                    href={item ? getUrl(item?.value) : "#"}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.value_type == "Link"
                                      ? "E-Script"
                                      : item?.value
                                          ?.split("/")[5]
                                          ?.substring(14)}
                                  </a>
                                ) : (
                                  item?.value
                                )}
                              </td>
                              <td>{item?.date}</td>
                              <td>
                                {item.status == 0
                                  ? "Unsent"
                                  : item.status == 1 && !item.answer
                                  ? "Sent"
                                  : item.status == 1 && item.answer
                                  ? "Answered"
                                  : "-"}
                              </td>
                              <td>
                                <UncontrolledDropdown direction="start">
                                  <DropdownToggle caret size="sm">
                                    Action
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {item.value_type == "Document" ? (
                                      <a
                                        href={item ? getUrl(item?.value) : "#"}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <DropdownItem>
                                          View Document
                                        </DropdownItem>
                                      </a>
                                    ) : null}
                                    {getRole() != "Patient" &&
                                    item.status == 0 ? (
                                      <DropdownItem
                                        onClick={() => {
                                          sendQuestionary({ id: item.id })
                                        }}
                                      >
                                        Send Questionary and E-Script
                                      </DropdownItem>
                                    ) : null}
                                    {item.answer ? (
                                      <DropdownItem
                                        onClick={() => {
                                          toggleAnswer({ id: item.id })
                                          setModalData(item)
                                        }}
                                      >
                                        View Answer
                                      </DropdownItem>
                                    ) : null}
                                    <DropdownItem
                                      onClick={() => {
                                        setShowDelete(true)
                                        setSelectedId(item.id)
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ) : null
                        )
                      : null}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showModalAnswer ? (
        <QuestionaryAnswer
          data={modalData}
          modal={showModalAnswer}
          toggle={() => setShowModalAnswer(false)}
          refresh={refetch}
        />
      ) : null}
      <DocumentModal
        modal={showModal}
        toggle={() => setShowModal(false)}
        handleCreateModal={toggleModal}
        data={data}
        type={type}
        refetch={refetch}
      />
      <DeleteConfirmation
        showDelete={showDelete}
        setShowDelete={() => setShowDelete(false)}
        deleteAction={onDelete}
      />
    </React.Fragment>
  )
}

ScriptReminder.propTypes = {
  data: PropTypes.array,
  refetch: PropTypes.func,
};

export default React.memo(ScriptReminder)
