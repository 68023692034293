import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { showToast, toFormdata } from 'helpers/util';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import ReactSelect from 'react-select';

const CreateUpdateExternalDoctor = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
      // brands: data ? data.brands.map(item => item.id) : null,
    }
  });

  const { data: brands, isLoading: loadingBrand } = useQuery(
    ["select-brands"],
    () => api.selectBrand()
  )

  const { data: specialists, isLoading: loadingSpecialist } = useQuery(
    ["select-specialists"],
    () => api.selectSpecialist()
  )
  console.log(specialists)

  const { mutate } = useMutation((params) => {
    return data ? api.updateExternalDoctor(data.id, toFormdata(params, true)) : api.addExternalDoctor(toFormdata(params))
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      setLoading(false)
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit brand', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    }else{
      setValue('id', null)
    }
    reset(
      data
        ? {
            ...data,
            id: data ? data.id : null,
            name: data ? data.name : null,
            brands: data ? data.brands.map(item => item.id) : null,
            coupon: data ? data.coupon : null,
          }
        : null
    )
    setErrors([])
  }, [data])

  const onSubmit = () => mutate(getValues());

  return (
    <Modal toggle={() => handleCreateModal()} isOpen={modal} centered size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update External Doctor" : "Create External Doctor"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Name"
                  defaultValue={data?.name}
                  onChange={e => setValue("name", e.target.value)}
                />
                {errors?.name && (
                  <span className="form-text text-danger">
                    {errors?.name[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-gender">Gender</Label>
                <select
                  className="form-control"
                  defaultValue={data?.gender}
                  onChange={e => setValue("gender", e.target.value)}
                >
                  <option value="">Choose Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                {errors?.gender && (
                  <span className="form-text text-danger">
                    {errors?.gender[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-email">Email</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-email"
                  placeholder="Email"
                  defaultValue={data?.email}
                  onChange={e => setValue("email", e.target.value)}
                />
                {errors?.email && (
                  <span className="form-text text-danger">
                    {errors?.email[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-phone">Phone</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-phone"
                  placeholder="Phone"
                  defaultValue={data?.phone}
                  onChange={e => setValue("phone", e.target.value)}
                />
                {errors?.phone && (
                  <span className="form-text text-danger">
                    {errors?.phone[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-state">State</Label>
                <select
                  className="form-control"
                  defaultValue={data?.state}
                  onChange={e => setValue("state", e.target.value)}
                >
                  <option value="">Choose State</option>
                  <option value="Australian Capital Territory">
                    Australian Capital Territory
                  </option>
                  <option value="New South Wales">New South Wales</option>
                  <option value="Northern Territory">Northern Territory</option>
                  <option value="Queensland">Queensland</option>
                  <option value="South Australia">South Australia</option>
                  <option value="Tasmania">Tasmania</option>
                  <option value="Victoria">Victoria</option>
                  <option value="Western Australia">Western Australia</option>
                  <option value="Australia wide">Australia wide</option>
                </select>
                {errors?.state && (
                  <span className="form-text text-danger">
                    {errors?.state[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Address</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Address"
                  defaultValue={data?.address}
                  onChange={e => setValue("address", e.target.value)}
                />
                {errors?.address && (
                  <span className="form-text text-danger">
                    {errors?.address[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-specialist">
                  Practitioner Type / Specialist
                </Label>
                <select
                  id="formrow-specialist"
                  className="form-control"
                  defaultValue={data?.specialist?.id}
                  onInput={e => setValue("specialist_id", e.target.value)}
                >
                  <option value="">Choose...</option>
                  {specialists?.map(specialist => (
                    <option value={specialist.value} key={specialist.value}>
                      {specialist.label}
                    </option>
                  ))}
                </select>
                {errors?.specialist_id && (
                  <span className="form-text text-danger">
                    {errors?.specialist_id[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-consult-type">Consult Type</Label>
                <ReactSelect
                  options={[
                    { label: "In Person", value: "In Person" },
                    { label: "Telehealth", value: "Telehealth" },
                  ]}
                  isMulti
                  onChange={e =>
                    setValue(
                      "consult_type",
                      e.map(val => val.value)
                    )
                  }
                  defaultValue={data?.consult_type?.map(type => ({ label: type, value: type }))}
                />
                {errors?.consult_type && (
                  <span className="form-text text-danger">
                    {errors?.consult_type[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Brand</Label>
                <ReactSelect
                  options={brands ?? []}
                  isMulti
                  isLoading={loadingBrand}
                  onChange={e =>
                    setValue(
                      "brands",
                      e.map(val => val.value)
                    )
                  }
                  defaultValue={brands?.filter(ids =>
                    data?.brands?.map(brand => brand.id).includes(ids.value)
                  )}
                />
                {errors?.brands && (
                  <span className="form-text text-danger">
                    {errors?.brands[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-coupon">Coupon</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-coupon"
                  placeholder="Coupon"
                  defaultValue={data?.coupon}
                  onChange={e => setValue("coupon", e.target.value)}
                  maxLength={8}
                />
                {errors?.coupon && (
                  <span className="form-text text-danger">
                    {errors?.coupon[0]}
                  </span>
                )}
                <small>Max 8 characters</small>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-website">Website</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-website"
                  placeholder="Website"
                  defaultValue={data?.website}
                  onChange={e => setValue("website", e.target.value)}
                />
                {errors?.website && (
                  <span className="form-text text-danger">
                    {errors?.website[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-photo">Photo</Label>
                <Input
                  type="file"
                  className="form-control"
                  id="formrow-photo"
                  placeholder="Photo"
                  onChange={e => setValue("photo", e.target.files[0])}
                />
                {errors?.photo && (
                  <span className="form-text text-danger">
                    {errors?.photo[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit(onSubmit())
            }}
          >
            {loading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateExternalDoctor.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdateExternalDoctor);