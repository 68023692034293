import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap"
import { showToast, toFormdata } from "helpers/util"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import Select from "react-select"

const QuestionaryAnswer = ({ data, toggle, modal, refresh }) => {
  console.log(data)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "e-script",
      value_type: "Document",
    },
  })

  const { data: questionaries, isLoading } = useQuery(
    ["questionary-question"],
    () => api.getQuestionaryQuestion(),
  )

  const { mutate, isLoading: submitLoading } = useMutation(
    params => {
      return api.submitQuestionary(params)
    },
    {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        setLoading(false)
        if (!res.status) {
          showToast("Failed to submit questionary", "error")
          return
        }
        toggle()
        refresh()
      },
      onError: err => {
        showToast("Failed to submit pharmacy", "error")
      },
    }
  )

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.user
    const requirement = user.requirement.find(
      item => item.type == "e-script" && !item.answer
    )
    if (user?.patient?.is_exist == 1 && !requirement) {
      setStep(0)
    }
  }, [])

  return (
    <Modal
      isOpen={modal}
      centered
      size="lg"
      contentClassName="h-unset"
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Questionary Answer</h5>
      </div>
      <div className="modal-body">
        {isLoading ? (
          <Spinner className="ms-2 spinner-loading" color="success" />
        ) : (
          <div id="form-wrapper" className="py-5">
            <form className="w-100">
              {questionaries?.questions?.map((question, i) =>
                question.type == "rate" ? (
                  <div key={question.id} className="my-3">
                    <h3 className="text-center mb-3">
                      Q{i + 1}. {question.question}
                    </h3>
                    <div id="debt-amount-slider">
                      <input
                        type="radio"
                        name={`question_${i}_0`}
                        id={`question_${i}_0`}
                        value="0"
                        disabled
                        checked={data.answer.answer[i] == "0"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_0`}
                        data-debt-amount="0"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_1`}
                        id={`question_${i}_1`}
                        value="1"
                        disabled
                        checked={data.answer.answer[i] == "1"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_1`}
                        data-debt-amount="1"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_2`}
                        id={`question_${i}_2`}
                        value="2"
                        disabled
                        checked={data.answer.answer[i] == "2"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_2`}
                        data-debt-amount="2"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_3`}
                        id={`question_${i}_3`}
                        value="3"
                        disabled
                        checked={data.answer.answer[i] == "3"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_3`}
                        data-debt-amount="3"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_4`}
                        id={`question_${i}_4`}
                        value="4"
                        disabled
                        checked={data.answer.answer[i] == "4"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_4`}
                        data-debt-amount="4"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_5`}
                        id={`question_${i}_5`}
                        value="5"
                        disabled
                        checked={data.answer.answer[i] == "5"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_5`}
                        data-debt-amount="5"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_6`}
                        id={`question_${i}_6`}
                        value="6"
                        disabled
                        checked={data.answer.answer[i] == "6"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_6`}
                        data-debt-amount="6"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_7`}
                        id={`question_${i}_7`}
                        value="7"
                        disabled
                        checked={data.answer.answer[i] == "7"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_7`}
                        data-debt-amount="7"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_8`}
                        id={`question_${i}_8`}
                        value="8"
                        disabled
                        checked={data.answer.answer[i] == "8"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_8`}
                        data-debt-amount="8"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}_9`}
                        id={`question_${i}_9`}
                        value="9"
                        disabled
                        checked={data.answer.answer[i] == "9"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_9`}
                        data-debt-amount="9"
                      ></label>
                      <input
                        type="radio"
                        name={`question_${i}1_0`}
                        id={`question_${i}_10`}
                        value="10"
                        disabled
                        checked={data.answer.answer[i] == "10"}
                        {...register(`answer.${i}`, {
                          required: "This field is required",
                        })}
                      />
                      <label
                        htmlFor={`question_${i}_10`}
                        data-debt-amount="10"
                      ></label>
                      <div id="debt-amount-pos"></div>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <h4>{question.low_option_name}</h4>
                      <h4>{question.high_option_name}</h4>
                    </div>
                  </div>
                ) : (
                  <div key={question.id}>
                    <h3 className="text-center mb-3">
                      Q{i + 1}. {question.question}
                    </h3>
                    <textarea
                      className="form-control"
                      id="formrow-description-Input"
                      placeholder="Answer"
                      defaultValue={data.answer.answer[i]}
                      onChange={e =>
                        setValue(
                          `answer.${i}`,
                          e.target.value
                        )
                      }
                      rows={5}
                    ></textarea>
                  </div>
                )
              )}
            </form>
          </div>
        )}
      </div>
    </Modal>
  )
}

QuestionaryAnswer.propTypes = {
  data: PropTypes.any,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
}

export default React.memo(QuestionaryAnswer)
