import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, Row, Spinner, Table } from "reactstrap";
import { map } from "lodash";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/logo-dark.png";
import { get } from "helpers/api_helper";
import { currency, formatDateTime } from "helpers/util";
import Payment from "./Payment";
import PaymentList from "./component/PaymentList";

const DetailInvoice = () => {
  const routeParams = useParams()
  const history = useHistory();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [modalPayment, setModalPayment] = useState(false);
  const [modalData, setModalData] = useState(null)

  const getData = async () => {
    setLoading(true)
    const invoice = await get(`/invoice/${routeParams.id}`);
    if (!invoice.status) {
      history.push('/invoices');
    }
    setData(invoice);
    setLoading(false)
  };

  useEffect(async () => {
    getData();
  }, []);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  const togglePayment = () => {
    if (modalPayment) {
      setModalPayment(false);
    } else {
      setModalPayment(true);
    }
  };

  const handlePaymentModal = (data) => {
    togglePayment();
    setModalData(data)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Aeris Clinic
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
          {loading || !data ? (
            <Spinner className="ms-2 spinner-loading" color="success" />
          ) : (
            <>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody className="print-full-height">
                        <div className="invoice-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img src={logo} alt="logo" height="65" />
                        </div>
                        <div className="d-flex">
                          {data.paid_amount - data.total < 0 && (
                            <button
                              type="button"
                              className="btn btn-success me-3 print-btn"
                              onClick={() => handlePaymentModal(data)}
                            >
                              <i className="bx bx-money font-size-16 align-middle me-2"></i>{" "}
                              Pay Now
                            </button>
                          )}
                          <h4 className="mt-2 font-size-16">
                            Order # {data?.id}
                          </h4>
                        </div>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6">
                          <address>
                            <strong>
                              Billed To:
                            </strong>
                            <br />
                            {data.user.fullname}
                            <br />
                            {data.user.email}
                            <br />
                            {data.user.phone}
                            <br />
                            {map(
                              data.user.address?.split(","),
                              (item, key) => (
                                <React.Fragment key={key}>
                                  <span>{item}</span>
                                  <br />
                                </React.Fragment>
                              )
                            )}
                          </address>
                        </Col>
                        <Col xs="6" className="text-end">
                          <address>
                            <strong>Payment Detail:</strong>
                            {data.refund_amount > 0 && (
                              <>
                                <br />
                                Refund Amount : ${data.refund_amount.toFixed(2)}
                                <br />
                                Refund Status : {data.refund_status ?? '-'}
                              </>
                            )}
                            <br />
                              <Badge color={data.status == 'PARTIALLY' ? 'warning' : data.status == 'PAID' ? 'success' : 'danger'} className="font-size-16 mt-2">
                                {data.status}
                              </Badge>
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" className="mt-3">
                          <address>
                            <strong>Order Date:</strong>
                            <br />
                            {formatDateTime(data?.created_at)}
                            <br />
                            <br />
                          </address>
                        </Col>
                        {data.payment.length == 1 ? (
                          <Col xs="6" className="mt-3 text-end">
                            <address>
                              <strong>Payment Method:</strong>
                              {data?.payment?.type}
                              <br />
                                {data.payment && data?.payment[0]?.type == 'Credit Card' ? `Credit Card ending **** ${data?.payment[0].last4}` : data?.payment[0]?.type}
                            </address>
                          </Col>
                        ) : data.payment.length == 2 && (
                          <Col xs="6" className="mt-3 text-end">
                            <address>
                              <strong>Payment Method 1:</strong>
                              {data?.payment?.type}
                              <br />
                                {data.payment && data?.payment[0]?.type == 'Credit Card' ? `Credit Card ending **** ${data?.payment[0].last4}` : data?.payment[0]?.type}
                                <br />
                                {data?.payment[0].type == 'Credit Card' && (
                                  <a href={data.payment[0].receipt_url} target="_blank" rel="noreferrer">Transaction Record</a>
                                )}
                            </address>
                            <address>
                              <strong>Payment Method 2:</strong>
                              {data?.payment?.type}
                              <br />
                                {data.payment && data?.payment[1]?.type == 'Credit Card' ? `Credit Card ending **** ${data?.payment[1].last4}` : data?.payment[1]?.type}
                                <br />
                                {data?.payment[1].type == 'Credit Card' && (
                                  <a href={data.payment[1].receipt_url} target="_blank" rel="noreferrer">Transaction Record</a>
                                )}
                            </address>
                          </Col>
                        )}
                      </Row>
                      {data.appointment && (
                        <Row>
                          <b className="">Appoinment Date:</b>
                          <br />
                          <span>{formatDateTime(data.appointment?.date)}</span>
                        </Row>
                      )}
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 fw-bold">Order summary</h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Item</th>
                              <th className="text-end">Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(
                              data?.invoice_detail,
                              (item, key) => (
                                item.deleted_at ? (
                                  <tr key={key}>
                                    <td><strike>{item.product.sku}</strike></td>
                                    <td><strike>{item.product.name}</strike></td>
                                    <td className="text-end"><strike>{currency(item.amount)}</strike></td>
                                  </tr>
                                ) : (
                                  <tr key={key}>
                                    <td>{item.product.sku}</td>
                                    <td>{item.product.name}</td>
                                    <td className="text-end">{currency(item.amount)}</td>
                                  </tr>
                                )
                              )
                            )}
                            <tr>
                              {data.doctor && (
                                <td colSpan={1} className="border-0">
                                  <h4>
                                    <strong>{data.doctor?.user.fullname}</strong>
                                  </h4>
                                  <strong className="text-secondary text-uppercase">{data.doctor?.qualification}</strong>
                                  <br />
                                  <p className="text-success">Provider Number : {data.doctor?.provider_number}</p>
                                </td>
                              )}
                              <td colSpan={3} className="border-0">
                                <Table className="table-nowrap float-end" style={{ width: '30%' }}>
                                  <tbody>
                                    <tr>
                                      <th>Subtotal</th>
                                      <td className="text-end">{currency(data.total)}</td>
                                    </tr>
                                    {data.payment.map((item, key) => (
                                      item.type == 'Credit Card' && (
                                        <tr key={item.id}>
                                          <td>{key == 0 ? 'First' : 'Second'} Merchant Fee</td>
                                          <td className="text-end">{currency(item.fee)}</td>
                                        </tr>
                                      )
                                    ))}
                                    {data.paid_amount - data.total - data.refund_amount < 0 && (
                                      <tr>
                                        <th>Remaining Balance</th>
                                        <td className="text-end">{currency((data.paid_amount - data.total - data.refund_amount).toFixed(2))}</td>
                                      </tr>
                                    )}
                                    <tr>
                                      <th>Total</th>
                                      <td className="text-end">{currency(data.total + data.merchant_fee)}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-print-none text-end">
                        <div className="mt-n4">
                          <Link
                            to="#"
                            onClick={printInvoice}
                            className="btn btn-success  me-2"
                          >
                            <i className="fa fa-print" />
                          </Link>
                          {/* <Link to="#" className="btn btn-primary w-md ">
                            Send
                          </Link> */}
                        </div>
                      </div>
                      <PaymentList data={data} refetch={getData} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <Payment modal={modalPayment} toggle={togglePayment} data={modalData} refresh={getData} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(React.memo(DetailInvoice));
