import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row, Spinner, UncontrolledButtonDropdown
} from "reactstrap";

import { get } from "helpers/api_helper";
import { formatDateTime, getRole } from "helpers/util";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const DashboardDoctor = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    if (!data) {
      const dashboard = await get('/dashboard');
      setLoading(false)
      setData(dashboard.data)
    }
  }, [])

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
      order: 'desc',
    },
  ];

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    totalSize: data?.total, // replace later with size(orders),
    custom: true,
  };

  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "client.fullname",
      text: "Client",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/patients/${row.client?.id}`}>{row.client?.fullname}</Link>
      ),
    },
    {
      dataField: "type.name_type",
      text: "Client Type",
      sort: true,
    },
    {
      dataField: "doctor.fullname",
      text: "Doctors",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-center">
          {formatDateTime(row.date)}
          <span className="text-danger d-block">
            {row.is_reschedule ? "Reschedule" : ""}
          </span>
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${
            !row.invoice
              ? "danger"
              : row.invoice.status == "PAID"
              ? "success"
              : row.invoice.status == "PARTIALLY"
              ? "warning"
              : "danger"
          }`}
          color={`font-size-12 badge-soft-${
            !row.invoice
              ? "danger"
              : row.invoice.status == "PAID"
              ? "success"
              : row.invoice.status == "PARTIALLY"
              ? "warning"
              : "danger"
          }`}
          pill
        >
          {row.invoice?.status || "UNPAID"}
        </Badge>
      ),
    },
    {
      dataField: "approved_by",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "meeting_preferences",
      text: "Meeting Via",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>Action</DropdownToggle>
            <DropdownMenu>
              {row.meeting_preferences != "F2F" &&
                row?.invoice?.status == "PAID" &&
                (getRole() != "Staff" ? (
                  row.meeting_preferences == "Microsoft Teams" ? (
                    <DropdownItem>
                      <a
                        href={
                          row?.invoice?.status == "PAID"
                            ? row.doctor?.doctor?.ms_team_link
                            : undefined
                        }
                        target="_blank"
                        rel="noreferrer"
                        onClick={e => handleJoinMeeting(e, row)}
                      >
                        Join Meeting
                      </a>
                    </DropdownItem>
                  ) : row.meeting_preferences == "Zoom" ? (
                    <DropdownItem>
                      <a
                        href={
                          row?.invoice?.status == "PAID"
                            ? row.doctor?.doctor?.zoom_link
                            : undefined
                        }
                        target="_blank"
                        rel="noreferrer"
                        onClick={e => handleJoinMeeting(e, row)}
                      >
                        Join Meeting
                      </a>
                    </DropdownItem>
                  ) : null
                ) : null)}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <Spinner className="ms-2 spinner-loading" color="success" />
      ) : (
        <>
          <Row>
            <Col md={4}>
              <Link to="/appointments">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Today Appointments
                      </h4>
                      <h2 className="mb-0">{data?.today_appointment} Appointments</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/appointments">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Upcoming Appointments
                      </h4>
                      <h2 className="mb-0">{data?.upcoming_appointment} Appointments</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col md={4}>
              <Link to="/appointments">
                <Card body>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h4 className="text-muted fw-large">
                        Finish Appointments
                      </h4>
                      <h2 className="mb-0">{data?.finish_appointment} Appointments</h2>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-success">
                        <i className='bx bxs-right-arrow-circle font-size-24'></i>
                      </span>
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <>
                      <CardTitle>10 Today Appointment List</CardTitle>
                      {data && data.today_appointment_list && (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="id"
                          columns={EcommerceOrderColumns()}
                          data={data.today_appointment_list}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={data.today_appointment_list}
                              columns={EcommerceOrderColumns()}
                              bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )
                      }
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default React.memo(DashboardDoctor);
