import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { get, post, put } from 'helpers/api_helper';
import { debounce } from 'lodash';
import { formatDateTimeInput, getRole, showToast } from 'helpers/util';
import classnames from 'classnames';
import api from 'helpers/api';
import { useMutation, useQueries } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

const CreateUpdatePatient = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [dataType, setDataType] = useState([])
  const [dataTypeProblem, setDataTypeProblem] = useState([])
  const [doctors, setDoctors] = useState([])
  const [gp, setGp] = useState([])
  const [errors, setErrors] = useState([])
  const results = useQueries({
    queries: [
      {
        queryKey: ['get-patient-type', 1], queryFn: () => api.getPatientType(), onSuccess: (res) => {
          setDataType(res)
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['get-patient-problem', 2], queryFn: () => api.getPatientProblem(), onSuccess: (res) => {
          setDataTypeProblem(res)
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['get-doctor', 3], queryFn: () => api.getSelectDoctor(), onSuccess: (res) => {
          setDoctors(res)
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['get-gp', 4], queryFn: () => api.getSelectGp(), onSuccess: (res) => {
          setGp(res)
        },
        refetchOnWindowFocus: false,
      },
    ],
  })

  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    } else {
      setValue('id', null)
    }
    setErrors([]);
    reset({
      ...data,
      ...data?.user,
      emergency_name: data?.user.emergency_contact?.first_name,
      emergency_last_name: data?.user.emergency_contact?.last_name,
      emergency_email: data?.user.emergency_contact?.email,
      emergency_phone: data?.user.emergency_contact?.phone,
      emergency_relationship: data?.user.emergency_contact?.relationship,
      doctor: data?.doctors?.map(val => val.id),
      patient_type: data?.type_patients_id,
      patient_type_problem: data?.type_presenting_problem_id,
    } ?? {});
  }, [data])

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    var formData = new FormData();
    if (data) formData.set('_method', 'PUT');
    for (const key in params) {
      if (params[key]) {
        formData.set(key, params[key]);
      }
    }
    if (params.referral?.length) {
      for (let i = 0; i < params.referral.length; i++) {
        formData.set(`referral[${i}]`, params.referral[i]);
      }
    }
    if (params.script?.length) {
      for (let i = 0; i < params.script.length; i++) {
        formData.set(`script[${i}]`, params.script[i]);
      }
    }
    if (params.other?.length) {
      for (let i = 0; i < params.other.length; i++) {
        formData.set(`other[${i}]`, params.other[i]);
      }
    }
    if (params.doctor_document?.length) {
      for (let i = 0; i < params.doctor_document.length; i++) {
        formData.set(`doctor_document[${i}]`, params.doctor_document[i]);
      }
    }
    if (params.doctor?.length) {
      for (let i = 0; i < params.doctor.length; i++) {
        formData.set(`doctor[${i}]`, params.doctor[i]);
      }
    }
    return data ? api.updatePatient(formData, params.id) : api.addPatient(formData)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit patient', 'error')
    }
  })

  const onSubmit = () => mutate(getValues());

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Patient" : "Create Patient"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col md="3">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Emergency Contact
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3");
                    }}
                  >
                    Document
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Password
                  </NavLink>
                </NavItem>
                {getRole() != "Patient" ? (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "5",
                      })}
                      onClick={() => {
                        toggleVertical("5")
                      }}
                    >
                      Assign
                    </NavLink>
                  </NavItem>
                ) : null}
                {getRole() != "Patient" ? (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "6",
                      })}
                      onClick={() => {
                        toggleVertical("6")
                      }}
                    >
                      Roles
                    </NavLink>
                  </NavItem>
                ) : null}
              </Nav>
            </Col>
            {/* {results.filter(query => query.isLoading == false).length == 5 ? ( */}
            <Col md="9">
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={data?.user.name}
                          onInput={e => setValue("name", e.target.value)}
                        />
                        {errors.name && (
                          <span className="form-text text-danger">
                            {errors.name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.user.last_name}
                          onInput={e => setValue("last_name", e.target.value)}
                        />
                        {errors.last_name && (
                          <span className="form-text text-danger">
                            {errors.last_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">Email</Label>
                        <Input
                          type="search"
                          className="form-control"
                          id="formrow-email-Input"
                          placeholder="Email"
                          defaultValue={data?.user.email}
                          onInput={e => setValue("email", e.target.value)}
                          autoComplete="off"
                        />
                        {errors.email && (
                          <span className="form-text text-danger">
                            {errors.email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-phone-Input">Phone</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.user.phone}
                          onInput={e => setValue("phone", e.target.value)}
                        />
                        {errors.phone && (
                          <span className="form-text text-danger">
                            {errors.phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Date of Birth</Label>
                        <Input
                          type="date"
                          className="form-control"
                          id="formrow-dob-Input"
                          placeholder="Date of Birth"
                          defaultValue={data?.b_date}
                          onInput={e => setValue("b_date", e.target.value)}
                        />
                        {errors.b_date && (
                          <span className="form-text text-danger">
                            {errors.b_date[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Gender">Gender</Label>
                        <select
                          id="formrow-Gender"
                          className="form-control"
                          defaultValue={data?.gender}
                          onInput={e => setValue("gender", e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.gender && (
                          <span className="form-text text-danger">
                            {errors.gender[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  {dataType.length > 0 && dataTypeProblem.length > 0 ? (
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-dob-Input">
                            Patient Type
                          </Label>
                          <select
                            id="formrow-PatientType"
                            className="form-control"
                            defaultValue={data?.type_patients_id}
                            onInput={e =>
                              setValue("patient_type", e.target.value)
                            }
                          >
                            <option>Choose...</option>
                            {dataType?.map(item => (
                              <option value={item.value} key={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                          {errors.patient_type && (
                            <span className="form-text text-danger">
                              {errors.patient_type[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-PatientTypeProblem">
                            Patient Presenting Problem
                          </Label>
                          <select
                            id="formrow-PatientTypeProblem"
                            className="form-control"
                            defaultValue={data?.type_presenting_problem_id}
                            onInput={e =>
                              setValue("patient_type_problem", e.target.value)
                            }
                          >
                            <option>Choose...</option>
                            {dataTypeProblem?.map(item => (
                              <option value={item.value} key={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                          {errors.patient_type_problem && (
                            <span className="form-text text-danger">
                              {errors.patient_type_problem[0]}
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  <div className="mb-3">
                    <Label htmlFor="formrow-Address">Address</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      id="formrow-Address"
                      defaultValue={data?.address}
                      onInput={e => setValue("address", e.target.value)}
                    />
                    {errors.address && (
                      <span className="form-text text-danger">
                        {errors.address[0]}
                      </span>
                    )}
                  </div>

                  <Row>
                    {/* <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-irn-number-Input">IRN Number</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-irn-number-Input"
                            placeholder="IRN Number"
                            defaultValue={data?.irn_number}
                            onInput={(e) => setValue('irn_number', e.target.value)}
                          />
                          {errors.irn_number && <span className="form-text text-danger">{errors.irn_number[0]}</span>}
                        </div>
                      </Col> */}
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-medicare-number-Input">
                          Medicare Number
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-Medicare Number-Input"
                          placeholder="Medicare Number"
                          defaultValue={data?.medicare_number}
                          onInput={e =>
                            setValue("medicare_number", e.target.value)
                          }
                        />
                        {errors.medicare_number && (
                          <span className="form-text text-danger">
                            {errors.medicare_number[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-medicare-exp-Input">
                          Medicare Expired
                        </Label>
                        <Input
                          type="month"
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                          max={new Date().getFullYear() + "-12-31"}
                          className="form-control"
                          id="formrow-medicare-exp-Input"
                          placeholder="Medicare Expired"
                          defaultValue={data?.medicare_expired}
                          onInput={e =>
                            setValue("medicare_expired", e.target.value)
                          }
                        />
                        {errors.medicare_expired && (
                          <span className="form-text text-danger">
                            {errors.medicare_expired[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-first-name-Input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-first-name-Input"
                          placeholder="First Name"
                          defaultValue={
                            data?.user.emergency_contact?.first_name
                          }
                          onInput={e =>
                            setValue("emergency_name", e.target.value)
                          }
                        />
                        {errors.emergency_name && (
                          <span className="form-text text-danger">
                            {errors.emergency_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-last-name-Input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-last-name-Input"
                          placeholder="Last Name"
                          defaultValue={data?.user.emergency_contact?.last_name}
                          onInput={e =>
                            setValue("emergency_last_name", e.target.value)
                          }
                        />
                        {errors.emergency_last_name && (
                          <span className="form-text text-danger">
                            {errors.emergency_last_name[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-phone-Input">
                          Phone
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-emergency-phone-Input"
                          placeholder="Phone"
                          defaultValue={data?.user.emergency_contact?.phone}
                          onInput={e =>
                            setValue("emergency_phone", e.target.value)
                          }
                        />
                        {errors.emergency_phone && (
                          <span className="form-text text-danger">
                            {errors.emergency_phone[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-emergency-email-Input">
                          Email
                        </Label>
                        <Input
                          type="search"
                          className="form-control"
                          id="formrow-emergency-email-Input"
                          placeholder="Email"
                          defaultValue={data?.user.emergency_contact?.email}
                          onInput={e =>
                            setValue("emergency_email", e.target.value)
                          }
                          autoComplete="off"
                        />
                        {errors.emergency_email && (
                          <span className="form-text text-danger">
                            {errors.emergency_email[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-Relationship-Input">
                          Relationship
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="formrow-Relationship-Input"
                          placeholder="Relationship"
                          defaultValue={
                            data?.user.emergency_contact?.relationship
                          }
                          onInput={e =>
                            setValue("emergency_relationship", e.target.value)
                          }
                        />
                        {errors.emergency_relationship && (
                          <span className="form-text text-danger">
                            {errors.emergency_relationship[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId="3">
                    <Row>
                      <Col md={8}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-referral-Input">Referral</Label>
                          <Input className="form-control" type="file" id="formFile" onChange={(e) => setValue('referral', e.target.files)} multiple />
                          {errors.referral && <span className="form-text text-danger">{errors.referral[0]}</span>}
                          {Array.from({ length: 5 }).map((item, i) => errors[`referral.${i}`] && <span className="form-text text-danger">{errors[`referral.${i}`][0]}</span>)}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-referral-Input">Referral Expired</Label>
                          <Input
                            className="form-control" type="date"
                            max={new Date().getFullYear() + '-12-31'}
                            defaultValue={data?.user.requirement?.referral_expired}
                            onChange={(e) => setValue('referral_expired', e.target.value)}
                          />
                          {errors.referral_expired && <span className="form-text text-danger">{errors.referral_expired[0]}</span>}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-referral-Input">Script</Label>
                          <Input className="form-control" type="file" id="formFile" onChange={(e) => setValue('script', e.target.files)} multiple />
                          {errors.script && <span className="form-text text-danger">{errors.script[0]}</span>}
                          {Array.from({ length: 5 }).map((item, i) => errors[`script.${i}`] && <span className="form-text text-danger">{errors[`script.${i}`][0]}</span>)}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-referral-Input">Script Expired</Label>
                          <Input
                            className="form-control" type="date"
                            max={new Date().getFullYear() + '-12-31'}
                            defaultValue={data?.user.requirement?.medicare_expired}
                            onChange={(e) => setValue('medicare_expired', e.target.value)}
                          />
                          {errors.medicare_expired && <span className="form-text text-danger">{errors.medicare_expired[0]}</span>}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-referral-Input">Other</Label>
                          <Input className="form-control" type="file" id="formFile" onChange={(e) => setValue('other', e.target.files)} multiple />
                          {errors.other && <span className="form-text text-danger">{errors.other[0]}</span>}
                          {Array.from({ length: 5 }).map((item, i) => errors[`other.${i}`] && <span className="form-text text-danger">{errors[`other.${i}`][0]}</span>)}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-referral-Input">Doctor Document</Label>
                          <Input className="form-control" type="file" id="formFile" onChange={(e) => setValue('doctor_document', e.target.files)} multiple />
                          {errors.doctor_document && <span className="form-text text-danger">{errors.doctor_document[0]}</span>}
                          {Array.from({ length: 5 }).map((item, i) => errors[`doctor_document.${i}`] && <span className="form-text text-danger">{errors[`doctor_document.${i}`][0]}</span>)}
                        </div>
                      </Col>
                    </Row>
                  </TabPane> */}
                <TabPane tabId="4">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password-Input">Password</Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password-Input"
                          placeholder="Password"
                          onChange={e => setValue("password", e.target.value)}
                          autoComplete="new-password"
                        />
                        {errors.password && (
                          <span className="form-text text-danger">
                            {errors.password[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-password_confirmation-Input">
                          Password Confirmation
                        </Label>
                        <Input
                          type="password"
                          className="form-control"
                          id="formrow-password_confirmation-Input"
                          placeholder="Password Confirmation"
                          onChange={e =>
                            setValue("password_confirmation", e.target.value)
                          }
                          autoComplete="new-password"
                        />
                        {errors.password_confirmation && (
                          <span className="form-text text-danger">
                            {errors.password_confirmation[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">Doctors</Label>
                        {results[2].fetchStatus == "idle" ? (
                          <Select
                            options={doctors}
                            isMulti
                            onChange={e =>
                              setValue(
                                "doctor",
                                e.map(val => val.value)
                              )
                            }
                            defaultValue={
                              data?.doctors &&
                              doctors?.filter(doctor =>
                                data?.doctors?.find(
                                  userDoctor => doctor.value == userDoctor.id
                                )
                              )
                            }
                          />
                        ) : null}
                        {errors.doctor && (
                          <span className="form-text text-danger">
                            {errors.doctor[0]}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-dob-Input">
                          General Practice
                        </Label>
                        {results[3].fetchStatus == "idle" ? (
                          <Select
                            options={gp}
                            autoComplete="off"
                            onChange={e =>
                              setValue("general_practice_id", e.value)
                            }
                            defaultValue={
                              data?.general_practice_id && gp
                                ? gp?.find(
                                    item =>
                                      item.value == data.general_practice_id
                                  )
                                : null
                            }
                          />
                        ) : null}
                        {errors.general_practice_id && (
                          <span className="form-text text-danger">
                            {errors.general_practice_id[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="6">
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-permissions-Input">
                          Special Permissions
                        </Label>
                        <Select
                          // options={roles?.roles}
                          isMulti
                          onInput={e => setValue("permissions", e.value)}
                          // defaultValue={data && { label: data.client.fullname, value: data.client.id }}
                        />
                        {errors.roles && (
                          <span className="form-text text-danger">
                            {errors.roles[0]}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
            {/* ) : (<Spinner className="position-absolute spinner-loading" color="success" />)} */}
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault()
              handleSubmit(onSubmit())
            }}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdatePatient.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdatePatient);