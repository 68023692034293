import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { getRole, showToast } from "helpers/util"
import Confirmation from "components/Alert/Confirmation"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import ConfirmationInput from "components/Alert/ConfirmationInput"
import { breakSuccess, checkInSuccess } from "store/actions"
import QuestionaryModal from "components/QuestionaryModal"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const [user, setUser] = useState(null)
  const [modal, setModal] = useState(false)

  // const { data, isLoading, refetch } = useQuery(
  //   ["authenticated-user"],
  //   () => api.getAuthenticatedUser(),
  //   {
  //     onSuccess: res => {
  //       var checkHasQuestionary = res.user.requirement
  //         ?.filter(item => item.type == 'e-script')
  //         ?.some(item => !item.answer);
  //       if (checkHasQuestionary || (res.user.patient?.is_exist == 1 && res.user.requirement.length == 0)) {
  //         setModal(true)
  //       }

  //       localStorage.setItem("authUser", JSON.stringify(res))
  //     },
  //     refetchOnWindowFocus: true,
  //   }
  // )

  // const toggle = () => {
  //   if (modal) {
  //     setModal(false)
  //   } else {
  //     setModal(true)
  //   }
  // }

  const logout = () => {
    redirectLogout();
  }

  const redirectLogout = () => {
    localStorage.removeItem('authUser');
    window.location.replace(window.location.origin + '/login');
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setusername(obj?.user?.fullname)
      setUser(obj?.user)
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={
              user?.photo
                ? user?.photo
                : "https://img2.pngdownload.id/20180402/ojw/kisspng-united-states-avatar-organization-information-user-avatar-5ac20804a62b58.8673620215226654766806.jpg"
            }
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {getRole() == "Patient" ? (
            <DropdownItem
              tag="a"
              href={`/patients/${
                JSON.parse(localStorage.getItem("authUser")).user.id
              }`}
            >
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </DropdownItem>
          ) : (
            ""
          )}

          <a className="dropdown-item" onClick={() => logout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
      {/* <QuestionaryModal modal={modal} toggle={toggle} refresh={refetch} /> */}
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  isCheckIn: PropTypes.any,
  isBreak: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success, isCheckIn, isBreak } = state.Profile
  return { error, success, isCheckIn, isBreak }
}

const mapDispatchToProps = {
}

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(ProfileMenu))
)
