import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button, Badge, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import PropTypes from "prop-types"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { currency, formatDate, formatDateTime, getRole } from "helpers/util"
import CreateUpdateAppointment from "pages/Appointment/CreateUpdateAppointment"
import CreateUpdateInvoice from "pages/Invoice/CreateUpdateInvoice"
import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import CreateUpdateNote from "./CreateUpdateNote"

const PatientNote = ({ data, getData }) => {
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const {
    data: notes,
    refetch,
    isLoading,
  } = useQuery(["patient-note", data], () => api.getPatientNote({
    params: {
      patient_id: data?.patien_id
    }
  }), {
    refetchOnWindowFocus: false,
  })

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const handleCreateModal = () => {
    setModalData({ patient_id: data?.patien_id })
    toggle()
  }

  const handleUpdateModal = data => {
    toggle()
    setModalData(data)
  }

  const NoteTable = toggleModal => [
    {
      dataField: "note",
      text: "Note",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div dangerouslySetInnerHTML={{ __html: row.note ?? "" }}></div>
      ),
    },
    {
      dataField: "user.fullname",
      text: "Created By",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>Action</DropdownToggle>
            <DropdownMenu>
              {getRole() != "Patient" ? (
                <>
                  <DropdownItem onClick={() => handleUpdateModal(row)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setId(row.id)
                      setShowDelete(true)
                    }}
                  >
                    Delete
                  </DropdownItem>
                </>
              ) : null}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ]

  const pageOptionsNote = {
    sizePerPage: 10,
    totalSize: notes?.data.length, // replace later with size(orders),
    custom: true,
  }

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="d-md-flex justify-content-md-between align-items-center">
                <CardTitle className="mb-4">Notes</CardTitle>
                {getRole() != "Patient" && (
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={() => handleCreateModal()}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New Note
                  </Button>
                )}
              </div>
              {isLoading ? null : (
                <PaginationProvider
                  pagination={paginationFactory(pageOptionsNote)}
                  keyField="id"
                  columns={NoteTable()}
                  data={notes?.data}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={notes?.data}
                      columns={NoteTable()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          {/* <div className="pagination pagination-rounded justify-content-end">
                            <PaginationListStandalone {...paginationProps} />
                          </div> */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CreateUpdateNote
        data={modalData}
        modal={modal}
        toggle={toggle}
        refetch={refetch}
        handleCreateModal={handleCreateModal}
      />
    </React.Fragment>
  )
}

PatientNote.propTypes = {
  data: PropTypes.object,
  getData: PropTypes.func,
}

export default PatientNote;
