import React, { useState } from "react";
import PropTypes from "prop-types";
import { Pagination } from 'react-laravel-paginex'
import { Link, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { del } from "helpers/api_helper"

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CreateUpdateAppointment from "../CreateUpdateAppointment";

import {
  Button,
  Col,
  Row,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Spinner,
  Label,
} from "reactstrap";

import { formatDate, formatDateTime, getRole } from "helpers/util";
import DeleteConfirmation from "components/Alert/DeleteConfirmation";
import { useQuery } from "@tanstack/react-query";
import api from "helpers/api";

const AppointmentFinish = () => {
  const [id, setId] = useState('')
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [showDelete, setShowDelete] = useState(false)

  const { data: appointments, refetch, isLoading: appointmentsLoading } = useQuery(['get-appointment', search, page, limit], () => api.getFinishAppointment({
    params: {
      page: page,
      search: search,
      limit: limit,
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const deleteAction = async () => {
    const appointment = await del(`/appointment/${id}`);
    setShowDelete(false)
    refetch();
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleCreateModal = () => {
    toggle();
    setModalData(null)
  };

  const handleUpdateModal = (data) => {
    toggle();
    setModalData(data)
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: limit,
    pageStartIndex: 1,
    totalSize: appointments?.total, // replace later with size(appointments),
    custom: true,
  };

  const EcommerceAppointmentFinishColumns = toggleModal => [
    {
      dataField: "client.fullname",
      text: "Client",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/patients/${row.client?.id}`}>{row.client?.fullname}</Link>
      ),
    },
    {
      dataField: "type.name_type",
      text: "Client Type",
      sort: true,
    },
    {
      dataField: "doctor.fullname",
      text: "Doctor",
      sort: true,
    },
    {
      dataField: "date",
      text: "Appointment Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="text-center">
          {formatDateTime(row.date)}
          <span className="text-danger d-block">{row.is_reschedule ? 'Reschedule' : ''}</span>
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-${!row.invoice ? 'danger' : row.invoice.status == 'PAID' ? 'success' : row.invoice.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          color={`font-size-12 badge-soft-${!row.invoice ? 'danger' : row.invoice.status == 'PAID' ? 'success' : row.invoice.status == 'PARTIALLY' ? 'warning' : 'danger'}`}
          pill
        >
          {row.invoice?.status || 'UNPAID'}
        </Badge>
      ),
    },
    {
      dataField: "approved_by",
      text: "Staff",
      sort: true,
    },
    {
      dataField: "meeting_preferences",
      text: "Meeting Via",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>
              Action
            </DropdownToggle>
            <DropdownMenu>
              {(getRole() == 'Admin' || getRole() == 'Staff') && (
                <>
                  <DropdownItem>
                    Done
                  </DropdownItem>
                  <DropdownItem onClick={() => handleUpdateModal(row)}>
                    Edit
                  </DropdownItem>
                </>
              )}
              {getRole() == 'Admin' && (
                <DropdownItem onClick={() => {
                  setId(row.id);
                  setShowDelete(true);
                }}>
                  Delete
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      appointment: "desc",
      order: 'desc',
    },
  ];

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col>
          <div className="text-sm-end">
            {getRole() != "Patient" && getRole() != "Doctor" && (
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={() => handleCreateModal()}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Appointment
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <div className="row mb-4">
            <Label
              htmlFor="horizontal-firstname-Input"
              className="col-sm-4 col-form-label me-n5"
            >
              Show
            </Label>
            <Col sm={3}>
              <select
                id="formrow-limit"
                className="form-control"
                onChange={e => setLimit(e.target.value)}
                defaultValue={limit}
              >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </Col>
            <Label className="col-sm-4 col-form-label">entries</Label>
          </div>
        </Col>
        <Col sm="9">
          <div className="text-sm-end">
            <div className="search-box mt-c-sm-3 mb-3 d-md-inline-block">
              <div className="position-relative">
                <input
                  placeholder="Search..."
                  className="form-control"
                  onInput={e => {
                    setSearch(e.target.value)
                  }}
                  value={search}
                  type="text"
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {appointmentsLoading ? (
        <Spinner className="ms-2 spinner-loading" color="success" />
      ) : (
        <Row>
          <Col sm="12">
            {appointments && (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={EcommerceAppointmentFinishColumns(toggle)}
                data={appointments.data}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    data={appointments.data}
                    columns={EcommerceAppointmentFinishColumns(toggle)}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <Pagination
                              options={{
                                numberButtonClass: "page-item d-flex",
                              }}
                              changePage={e => setPage(e.page)}
                              data={appointments}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </Col>
        </Row>
      )}
      <CreateUpdateAppointment
        modal={modal}
        toggle={toggle}
        handleCreateModal={handleCreateModal}
        data={modalData}
        refresh={refetch}
      />
      <DeleteConfirmation
        showDelete={showDelete}
        setShowDelete={() => setShowDelete(false)}
        deleteAction={deleteAction}
      />
    </React.Fragment>
  )
};

AppointmentFinish.propTypes = {
  appointments: PropTypes.array,
  onGetAppointments: PropTypes.func,
  onAddNewAppointment: PropTypes.func,
  onDeleteAppointment: PropTypes.func,
  onUpdateAppointment: PropTypes.func,
};

export default withRouter(AppointmentFinish);

