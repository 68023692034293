import React, { useMemo, useState } from "react"
import { Card, CardBody, CardTitle, Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from "reactstrap"
import PropTypes from 'prop-types'
import { getRole, getUrl, showToast } from "helpers/util"
import { get } from "helpers/api_helper"
import DocumentModal from "./DocumentModal"
import { useMutation } from "@tanstack/react-query"
import api from "helpers/api"
import DeleteConfirmation from "components/Alert/DeleteConfirmation"

const type = 'referral_letter';

const ReferralLetter = ({ data, refetch }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false)

  const { mutate: deleteDocument, isLoading: deleteLoading } = useMutation((params) => api.removeDocument(params), {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      refetch()
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const document = useMemo(
    () => data?.user?.requirement?.filter(item => item.type == type),
    [data]
  )

  const toggleModal = () => {
    if (document?.length == 5) {
      showToast('Maximum upload is 5 file', 'error')
    }
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  };

  const onDelete = () => {
    deleteDocument({
      id: selectedId,
    })
  }

  const sendReminder = async (id) => {
    const res = await get(`/send-referral/${id}`);
    showToast(res.message)
  }

  return (
    <React.Fragment>
      <Card style={{ height: 400 }}>
        <CardBody>
          <div className="d-md-flex justify-content-between">
            <CardTitle className="mb-4">Referral Letter</CardTitle>
            <Button
              size="sm"
              color="primary"
              style={{ height: 30 }}
              onClick={toggleModal}
            >
              Upload New
            </Button>
          </div>
          <Row>
            <Col>
              <div className="table-responsive">
                <Table bordered>
                  <thead className="table-light">
                    <tr>
                      <th>Filename</th>
                      <th className="text-center">Date</th>
                      {/* <th className="text-center">Status</th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {document?.map((item, key) =>
                      item ? (
                        <tr key={key}>
                          <td>
                            <a
                              href={item ? getUrl(item?.value) : "#"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item?.value?.toString().split("/")[5]?.substring(14)}
                            </a>
                          </td>
                          <td>{item?.date}</td>
                          {/* <td>
                            {new Date(item?.date).getTime() < new Date().getTime() &&
                            item?.date
                              ? "Expired"
                              : "Active"}
                          </td> */}
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle caret size="sm">
                                Action
                              </DropdownToggle>
                              <DropdownMenu>
                                {getRole() != "Patient" ? (
                                  new Date(item?.date).getTime() <
                                    new Date().getTime() && item?.date ? null : (
                                    <DropdownItem
                                      onClick={() =>
                                        sendReminder(data.user.requirement?.id)
                                      }
                                    >
                                      Send Reminder{" "}
                                      {data.user.requirement?.reminder_counter > 1 &&
                                        "Again"}
                                    </DropdownItem>
                                  )
                                ) : null}
                                <a
                                  href={item ? getUrl(item?.value) : "#"}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <DropdownItem>View</DropdownItem>
                                </a>
                                <DropdownItem
                                  onClick={() => {
                                    setShowDelete(true)
                                    setSelectedId(item.id)
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DocumentModal
        modal={showModal}
        toggle={() => setShowModal(false)}
        handleCreateModal={toggleModal}
        data={data}
        type={type}
        refetch={refetch}
      />
      <DeleteConfirmation
        showDelete={showDelete}
        setShowDelete={() => setShowDelete(false)}
        deleteAction={onDelete}
      />
    </React.Fragment>
  )
}

ReferralLetter.propTypes = {
  data: PropTypes.array,
  refetch: PropTypes.func,
};

export default React.memo(ReferralLetter)
