import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { Col, Form, Input, Label, Modal, Row } from "reactstrap"
import { formatDateTimeInput, showToast } from "helpers/util"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { useForm } from "react-hook-form"
import Editor from "components/Common/Editor"

const CreateUpdateNote = ({
  data,
  toggle,
  modal,
  handleCreateModal,
  refetch,
}) => {
  const [errors, setErrors] = useState([])

  const {
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { formErrors },
  } = useForm({
    defaultValues: {
      note: data?.note
    },
  })

  const { mutate, isLoading: submitLoading } = useMutation(
    params => data ? api.updatePatientNote(data.id, params) : api.addPatientNote(params),
    {
      onSuccess: res => {
        if (res.status) {
          showToast(res.message, !res.status && "error")
          refetch()
          // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
          toggle()
        } else {
          setErrors(res.errors)
        }
      },
      onError: err => {
        console.log(err)
        showToast("Failed to submit", "error")
      },
    }
  )

  const onSubmit = () => {
    mutate({
      id: data?.id,
      patient_id: data?.patient_id,
      note: getValues("note"),
    })
  }

  useEffect(() => {
    reset(data ?? {})
    setErrors([])
  }, [data])

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      size="lg"
      contentClassName="mh-unset"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add Note</h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputDate">Notes</Label>
                <Editor
                  onChange={(event, editor) => {
                    setValue("note", editor.getData())
                  }}
                  data={data?.note ?? ""}
                />
                {errors.note && (
                  <span className="form-text text-danger">
                    {errors.note[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer d-md-flex justify-content-md-between">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateNote.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  type: PropTypes.string,
  userId: PropTypes.number,
  setSchedules: PropTypes.func,
  refetch: PropTypes.func,
}

export default CreateUpdateNote
