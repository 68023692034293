import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { showToast, toFormdata } from 'helpers/util';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';

const CreateUpdatePharmacy = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const { getValues, setValue, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      id: data ? data.id : null,
    }
  });
  const { mutate } = useMutation((params) => {
    return data ? api.updatePharmacy(data.id, params) : api.addPharmacy(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      setLoading(false)
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit pharmacy', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      setValue('id', data.id)
    }else{
      setValue('id', null)
    }
    reset(data ?? {});
    setErrors([])
  }, [data])

  const onSubmit = () => {
    mutate(toFormdata(getValues(), data ? true : false))
  };

  return (
    <Modal toggle={() => handleCreateModal()} isOpen={modal} centered size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Pharmacy" : "Create Pharmacy"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(onSubmit())
          }}
        >
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-name">Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-name"
                  placeholder="Name"
                  defaultValue={data?.name}
                  onChange={e => setValue("name", e.target.value)}
                />
                {errors?.name && (
                  <span className="form-text text-danger">
                    {errors?.name[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-phone">Phone</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-phone"
                  placeholder="Phone"
                  defaultValue={data?.phone}
                  onChange={e => setValue("phone", e.target.value)}
                />
                {errors?.phone && (
                  <span className="form-text text-danger">
                    {errors?.phone[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-dispense-email">Dispense Email</Label>
                <Input
                  type="email"
                  className="form-control"
                  id="formrow-dispense-email"
                  placeholder="Dispense Email"
                  defaultValue={data?.dispense_email}
                  onChange={e => setValue("dispense_email", e.target.value)}
                />
                {errors?.dispense_email && (
                  <span className="form-text text-danger">
                    {errors?.dispense_email[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-billing-email">Billing Email</Label>
                <Input
                  type="email"
                  className="form-control"
                  id="formrow-billing-email"
                  placeholder="Billing Email"
                  defaultValue={data?.billing_email}
                  onChange={e => setValue("billing_email", e.target.value)}
                />
                {errors?.billing_email && (
                  <span className="form-text text-danger">
                    {errors?.billing_email[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-billing-email">Website</Label>
                <Input
                  type="email"
                  className="form-control"
                  id="formrow-billing-email"
                  placeholder="Website"
                  defaultValue={data?.website}
                  onChange={e => setValue("website", e.target.value)}
                />
                {errors?.website && (
                  <span className="form-text text-danger">
                    {errors?.website[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Address Line 1</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Address Line 1"
                  defaultValue={data?.address_line_1}
                  onChange={e => setValue("address_line_1", e.target.value)}
                />
                {errors?.address_line_1 && (
                  <span className="form-text text-danger">
                    {errors?.address_line_1[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Address Line 2</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Address Line 2"
                  defaultValue={data?.address_line_2}
                  onChange={e => setValue("address_line_2", e.target.value)}
                />
                {errors?.address_line_2 && (
                  <span className="form-text text-danger">
                    {errors?.address_line_2[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-city">City</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-city"
                  placeholder="City"
                  defaultValue={data?.city}
                  onChange={e => setValue("city", e.target.value)}
                />
                {errors?.city && (
                  <span className="form-text text-danger">
                    {errors?.city[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-state">State</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-state"
                  placeholder="State"
                  defaultValue={data?.state}
                  onChange={e => setValue("state", e.target.value)}
                />
                {errors?.state && (
                  <span className="form-text text-danger">
                    {errors?.state[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-address">Postcode</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-address"
                  placeholder="Postcode"
                  defaultValue={data?.postcode}
                  onChange={e => setValue("postcode", e.target.value)}
                />
                {errors?.postcode && (
                  <span className="form-text text-danger">
                    {errors?.postcode[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="formrow-image-Input">Logo</Label>
                <Input
                  type="file"
                  className="form-control"
                  id="formrow-image-Input"
                  placeholder="Logo"
                  onInput={e => setValue("image", e.target.files[0])}
                />
                {errors.image && (
                  <span className="form-text text-danger">
                    {errors.image[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  className="form-check-control"
                  id="formrow-is-active-Input"
                  placeholder="Is Active"
                  defaultChecked={data?.is_active}
                  onInput={e => setValue("is_active", e.target.checked)}
                />
                <Label htmlFor="formrow-is-active-Input ml-3" check> Enabled / Disabled</Label>
                {errors.is_active && (
                  <span className="form-text text-danger">
                    {errors.is_active[0]}
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              handleSubmit(onSubmit())
            }}
          >
            {loading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdatePharmacy.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refresh: PropTypes.func,
  handleCreateModal: PropTypes.func,
};

export default React.memo(CreateUpdatePharmacy);