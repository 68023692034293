import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { formatDateInput, formatDateTimeInput, showToast } from 'helpers/util';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const CreateUpdateSchedule = ({ data, toggle, modal, handleCreateModal, refetch, modalDate, userId, setSchedules }) => {
  const [doctorSearch, setDoctorSearch] = useState('')
  const [patientSearch, setPatientSearch] = useState('')
  const [errors, setErrors] = useState([])

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { formErrors },
  } = useForm({
    defaultValues: {
      id: data ? data?.appointment?.id : null,
      appointment_date: data ? data?.appointment?.date : modalDate,
    },
  })

  const { data: doctors, isLoading: doctorLoading } = useQuery(['select-doctor', doctorSearch], () => api.getSelectDoctor({
    params: {
      search: doctorSearch,
      for: 'Appointment'
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const { data: patients, isLoading: patientLoading } = useQuery(['select-patient', patientSearch], () => api.getSelectPatient({
    params: {
      search: patientSearch,
      for: 'Appointment'
    }
  }), {
    refetchOnWindowFocus: false,
  });

  const { data: availability, loadingAvailability } = useQuery(
    ["check-availability", watch("date"), watch("doctor")],
    () =>
      api.checkAppointmentAvailability({
        params: {
          doctor: watch("doctor"),
          date: watch("date"),
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!(watch("doctor") && watch("date")),
    }
  )

  const getAppointmentTime = useMemo(() => {
    if (!getValues("doctor") && !getValues("meeting_type")) {
      return []
    }

    let doctor =
      data?.appointment?.doctor?.doctor ??
      doctors.find(item => item.id == getValues("doctor"))

    if (!doctor) {
      return []
    }

    let interval =
      getValues("meeting_type") == "Initial Appointment"
        ? doctor.initial_appointment
        : doctor.followup_appointment

    let dateArr = []
    let start = moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
    let end = moment().set({ hour: 22, minute: 0, second: 0, millisecond: 0 })

    if (data?.date) {
      dateArr.push(moment(data?.date).format("HH:mm"))
    }

    while (start <= end) {
      if (!availability?.includes(moment(start).format("HH:mm"))) {
        dateArr.push(moment(start).format("HH:mm"))
      }
      start = moment(start).add(interval, "minutes")
    }

    return dateArr
  }, [data, getValues, availability, modal])

  const { mutate: createSchedule } = useMutation((params) => data ? api.updateEvent(params) : api.addNewEvent(params), {
    onSuccess: (res) => {
      refetch()
      // !data && setSchedules(prev => [...prev, res?.event])
    },
  });

  const { mutate: deleteEvent, isLoading: deleteLoading } = useMutation(() => api.deleteEvent(data?.id), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        // !data.id && setSchedules(prev => [...prev, {...res?.event, start: formatDateTimeInput(res?.event.start)}])
        toggle()
      } else {
        setErrors(res.errors)
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit schedule', 'error')
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => {
    return data ? api.updateAppointment(params) : api.addAppointment(params)
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      let doctor = doctors.find(item => item.id == getValues("doctor"))
      createSchedule({
        id: data?.id,
        appointment_id: data ? data.appointment?.id : res.appointment?.id,
        user_id: userId,
        title: "Appointment",
        all_day: false,
        start: getValues("appointment_date"),
        end: moment(getValues("appointment_date"))
          .add(
            getValues("meeting_type") == "Initial Appointment"
              ? doctor.initial_appointment
              : doctor.followup_appointment,
            "minutes"
          )
          .format("DD-MM-YYYY HH:mm:ss"),
        class: "bg-primary external-event text-white p-1 mb-2 Appointment",
      })
      toggle()
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit schedule', 'error')
    }
  })

  const onSubmit = () => mutate(getValues());

  useEffect(() => {
    if (data) {
      setValue('id', data.appointment?.id)
    } else {
      setValue('id', null)
    }
    reset(
      {
        ...data,
        id: data ? data.appointment?.id : null,
        patient: data ? data.appointment?.client_id : null,
        doctor: data ? data.appointment?.doctor_id : null,
        appointment_date: data
          ? formatDateTimeInput(data.appointment?.date)
          : modalDate,
        meeting_preferences: data
          ? data.appointment?.meeting_preferences
          : null,
        meeting_type: data
          ? data.appointment?.meeting_type
          : null,
      } ?? {}
    )
  }, [data, modalDate, reset, setValue])

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Appointment" : "Create Appointment"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-email-Input">Patient</Label>
                <Select
                  options={patients}
                  onInputChange={e => setPatientSearch(e)}
                  onChange={e => setValue("patient", e.value)}
                  isLoading={patientLoading}
                  defaultValue={
                    data && {
                      label: data?.appointment?.client.fullname,
                      value: data?.appointment?.client.id,
                    }
                  }
                />
                {errors.patient && (
                  <span className="form-text text-danger">
                    {errors.patient[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-password-Input">Doctor</Label>
                <Select
                  options={doctors}
                  onInputChange={e => setDoctorSearch(e)}
                  onChange={e => setValue("doctor", e.id)}
                  isLoading={doctorLoading}
                  classNamePrefix="select2-selection"
                  defaultValue={
                    data && {
                      label: data?.appointment?.doctor.fullname,
                      value: data?.appointment?.doctor.id,
                    }
                  }
                />
                {errors.doctor && (
                  <span className="form-text text-danger">
                    {errors.doctor[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-Meeting">Meeting Preferences</Label>
                <select
                  id="formrow-Meeting"
                  className="form-control"
                  defaultValue={data?.appointment?.meeting_preferences}
                  onChange={e =>
                    setValue("meeting_preferences", e.target.value)
                  }
                >
                  <option value="">Choose...</option>
                  <option value="Zoom">Zoom</option>
                  <option value="Skype">Skype</option>
                  <option value="Telehealth">Telehealth</option>
                  <option value="Microsoft Teams">Microsoft Teams</option>
                  <option value="F2F">F2F</option>
                </select>
                {errors.meeting_preferences && (
                  <span className="form-text text-danger">
                    {errors.meeting_preferences[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-Meeting">Appointment Type</Label>
                <select
                  id="formrow-Meeting"
                  className="form-control"
                  defaultValue={data?.appointment?.meeting_type}
                  onChange={e => setValue("meeting_type", e.target.value)}
                >
                  <option value="">Choose...</option>
                  <option value="Initial Appointment">
                    Initial Appointment
                  </option>
                  <option value="Follow-up Appointment">
                    Follow-up Appointment
                  </option>
                  <option value="Custom">Custom</option>
                </select>
                {errors.meeting_type && (
                  <span className="form-text text-danger">
                    {errors.meeting_type[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputDate">Appointment Date</Label>
                <Input
                  type="date"
                  className="form-control"
                  id="formrow-InputDate"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                  placeholder="Appointment Date"
                  max="9999-12-31T23:59"
                  defaultValue={data?.appointment?.date ? formatDateInput(data?.appointment?.date) : null}
                  onInput={e => {
                    setValue("date", e.target.value)
                  }}
                />
                {errors.date && (
                  <span className="form-text text-danger">
                    {errors.date[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label htmlFor="formrow-time">Appointment Time</Label>
                <Select
                  options={getAppointmentTime?.map(time => ({
                    label: time,
                    value: time,
                  }))}
                  onChange={e => {
                    setValue(
                      "appointment_date",
                      moment(getValues("date") + " " + e.value).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )
                    )
                    // console.log(moment(getValues('date') + ' ' + e.value).format('DD-MM-YYYY HH:mm:ss'))
                    // console.log(moment(getValues('date') + ' ' + e.value).add(15, 'minutes').format('DD-MM-YYYY HH:mm:ss'))
                  }}
                  isDisabled={getAppointmentTime.length == 0}
                  isLoading={loadingAvailability}
                  placeholder="Choose Hours"
                  defaultValue={
                    data?.appointment?.date
                      ? {
                          label: moment(data?.appointment?.date).format("HH:mm"),
                          value: moment(data?.appointment?.date).format("HH:mm"),
                        }
                      : null
                  }
                />
                {errors.time && (
                  <span className="form-text text-danger">
                    {errors.time[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label htmlFor="formrow-Message">Message</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Message"
              id="formrow-Message"
              value={data?.appointment?.message}
              onInput={e => setValue("message", e.target.value)}
            />
            {errors.message && (
              <span className="form-text text-danger">{errors.message[0]}</span>
            )}
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-start">
          {data?.id && (
            <button
              type="submit"
              className="btn btn-danger w-md"
              disabled={deleteLoading}
              onClick={e => {
                e.preventDefault()
                deleteEvent()
              }}
            >
              {deleteLoading ? (
                <>
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                  Loading
                </>
              ) : (
                <span>Delete</span>
              )}
            </button>
          )}
        </div>
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

CreateUpdateSchedule.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refetch: PropTypes.func,
  handleCreateModal: PropTypes.func,
  type: PropTypes.string,
  modalDate: PropTypes.any,
  userId: PropTypes.number,
  setSchedules: PropTypes.func,
};

export default CreateUpdateSchedule;