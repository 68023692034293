import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, Row, UncontrolledTooltip } from 'reactstrap';
import { formatDateTimeInput, showToast } from 'helpers/util';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { useForm } from 'react-hook-form';

const DocumentModal = ({ data, toggle, modal, handleCreateModal, refetch, type }) => {
  const [errors, setErrors] = useState([])

  const { register, getValues, setValue, watch, handleSubmit, reset, formState: { formErrors } } = useForm({
    defaultValues: {
      value_type: 'Document'
    }
  });

  const { mutate, isLoading: submitLoading } = useMutation((params) => api.addDocument(params), {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message, !res.status && 'error')
        refetch()
        toggle()
      } else {
        if (res.errors) {
          setErrors(res.errors)
        }else{
          showToast(res.message, 'error')
        }
      }
    },
    onError: (err) => {
      console.log(err)
      showToast('Failed to submit', 'error')
    }
  });

  const onSubmit = () => {
    var formData = new FormData();
    formData.set(`files`, getValues('files'));
    formData.set('user_id', data.user.id)
    formData.set('type', type)
    formData.set("value", getValues("value"))
    formData.set("value_type", getValues("value_type"))
    formData.set('date', getValues('date'))
    mutate(formData)
  };

  const onFileChange = (e) => {
    if (e.target.files.length > 5) {
      showToast('Maximum upload is up to 5 files', 'error')
      e.target.value = null;
    }else{
      setValue('files', e.target.files[0])
    }
  }

  useEffect(() => {
    // if (data) {
    //   if (type == 'referral_letter') {
    //     setValue('date', data?.user?.requirement?.referral_expired)
    //   } else if (type == 'e-script') {
    //     setValue('date', data?.user?.requirement?.medicare_expired)
    //   }
    // }
    setErrors([]);
  }, [data])

  return (
    <Modal
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      contentClassName="h-50"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Upload Document</h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            onSubmit()
          }}
        >
          {type == "e-script" ? (
            <>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="formrow-files-Input">Type</Label>
                    <select
                      className="form-control"
                      {...register("value_type")}
                    >
                      <option value="Document">Document</option>
                      <option value="Link">Link</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {watch("value_type") == "Document" ? (
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-files-Input">
                        Document{" "}
                        <i
                          className="bx bx-question-mark rounded-circle border border-dark"
                          id="DocumentTooltip"
                        ></i>
                      </Label>
                      <UncontrolledTooltip
                        trigger="hover"
                        target="DocumentTooltip"
                      >
                        Upload the file of your current paper script.
                      </UncontrolledTooltip>
                      <Input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={e => onFileChange(e)}
                      />
                      {errors.files && (
                        <span className="form-text text-danger">
                          {errors.files[0]}
                        </span>
                      )}
                      {Array.from({ length: 5 }).map(
                        (item, i) =>
                          errors[`files.${i}`] && (
                            <span className="form-text text-danger" key={i}>
                              {errors[`files.${i}`][0]}
                            </span>
                          )
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-files-Input">
                        {watch("value_type") == "Token" ? "Token" : "Link"}{" "}
                        <i
                          className="bx bx-question-mark rounded-circle border border-dark"
                          id="ScriptTooltip"
                        ></i>
                      </Label>
                      <UncontrolledTooltip
                        trigger="hover"
                        target="ScriptTooltip"
                      >
                        The link of your current eRX script.
                      </UncontrolledTooltip>
                      <input
                        className="form-control"
                        type="text"
                        id="formFile"
                        {...register("value")}
                      />
                      {errors.value && (
                        <span className="form-text text-danger">
                          {errors.value[0]}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="formrow-files-Input">Document</Label>
                  <Input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={e => onFileChange(e)}
                  />
                  {errors.files && (
                    <span className="form-text text-danger">
                      {errors.files[0]}
                    </span>
                  )}
                  {Array.from({ length: 5 }).map(
                    (item, i) =>
                      errors[`files.${i}`] && (
                        <span className="form-text text-danger" key={i}>
                          {errors[`files.${i}`][0]}
                        </span>
                      )
                  )}
                </div>
              </Col>
            </Row>
          )}
          {type == "referral_letter" || type == "e-script" ? (
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="formrow-files-Input">Date Expired</Label>
                  <Input
                    className="form-control"
                    type="date"
                    max={new Date().getFullYear() + "-12-31"}
                    defaultValue={
                      type == "referral_letter"
                        ? data.user.requirement?.referral_expired
                        : data.user.requirement?.medicare_expired
                    }
                    onChange={e => setValue("date", e.target.value)}
                  />
                  {errors.files && (
                    <span className="form-text text-danger">
                      {errors.files[0]}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          ) : null}
        </Form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

DocumentModal.propTypes = {
  data: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  handleCreateModal: PropTypes.func,
  refetch: PropTypes.func,
  type: PropTypes.string,
};

export default React.memo(DocumentModal);